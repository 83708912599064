import app from "../old/application"
import MobileCrmData from "../MobileCrmData"
import { RelationFormModule } from "../submodule/crm/user_js/form/RelationFormModule"
import { RelationSelectModule } from "../submodule/crm/user_js/relation/RelationSelectModule"
import { FormDataResponse } from "./FormDataLoader"

export function FormExecutor( data: FormDataResponse, htmlId: string ) {
	const htmlElement: HTMLElement | null = document.getElementById( htmlId )
	if( htmlElement ) {
		htmlElement.innerHTML = data.extHtml
	}

	// @ts-ignore
	window.CrmMain = {
		RelationFormModule: RelationFormModule,
		RelationSelectModule: RelationSelectModule,
	}

	eval.call( window, data.javaScript )

	if( app.data.onload ) {
		for( let i = 0; i < app.data.onload.length; i++ ) {
			app.data.onload[ i ]()
		}
	}

	if( data.formId != MobileCrmData.lastFormId ) {
		MobileCrmData.lastFormId = data.formId
	}

	// if( app.data.onunload ) {
	// 	$( window ).unload( function() {
	// 		for( let i = 0; i < app.data.onunload.length; i++ ) {
	// 			app.data.onunload[ i ]()
	// 		}
	// 	} )
	// }
}