import * as React from "react"
import FilterFormDataLoader from "./FilterFormDataLoader"
import { useParams } from "react-router-dom"

const FilterForm = () => {
	const { table } = useParams() as { table: string }

	return (
		<FilterFormDataLoader table={ table } />
	)
}

export default FilterForm