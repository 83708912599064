import * as React from "react"
import { ReactNode, useEffect, useState } from "react"
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortAlphaDownAlt } from "@fortawesome/pro-solid-svg-icons/faSortAlphaDownAlt"
import { faSortAlphaUp } from "@fortawesome/pro-solid-svg-icons/faSortAlphaUp"
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck"
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes"
import MobileCrmData from "../MobileCrmData"
import { IFilter, SortFilter } from "./Filter"
import { FormattedMessage } from "react-intl"
import { fetchRawContentData } from "./FormDataHelper"
import { Alert, Snackbar } from "@mui/material"

type ListOrderFilterProps = {
	table: string,
	handleFilterChange: ( filter: IFilter ) => void
};

type ColumnOrderOption = { name: string, code: string }
export const ListOrderFilter = ( props: ListOrderFilterProps ) => {
	const [ columnOrderOptions, setColumnOrderOptions ] = useState<ColumnOrderOption[]>( [] )
	const [ open, setOpen ] = React.useState( false )
	const sortFilter: SortFilter = new SortFilter( props.table )

	const [ orderType, setOrderType ] = useState<string>( sortFilter.filter.orderType )
	const [ orderColumn, setOrderColumn ] = useState<string>( sortFilter.filter.orderColumn || "" )

	useEffect( () => {
		const config = {
			"params": {
				"table": props.table,
			},
		}

		fetchRawContentData( MobileCrmData.ORDER_COLUMNS_ACTION, config, setColumnOrderOptions )
	}, [ props.table ] )

	const submit = () => {
		sortFilter.filter.orderColumn = orderColumn
		sortFilter.filter.orderType = orderType
		sortFilter.saveToStorage()
		props.handleFilterChange( sortFilter.filter )
		setOpen( true )
	}

	const clearFilter = () => {
		sortFilter.removeFromStorage()
		props.handleFilterChange( sortFilter.filter )
		setOrderColumn( "" )
		setOrderType( "" )
	}

	const orderOptions: ReactNode[] = []
	columnOrderOptions.forEach( ( value: ColumnOrderOption, index: number ) => {
		orderOptions.push( <MenuItem key={ index } value={ value.code }>{ value.name }</MenuItem> )
	} )

	const handleClose = ( _event?: React.SyntheticEvent | Event, reason?: string ) => {
		if( reason === 'clickaway' ) {
			return
		}

		setOpen( false )
	}

	return (
		<>
			<div style={ {
				display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: "1em",
			} }>
				<FormControl style={ { width: "60%" } }>
					<InputLabel htmlFor="selectOrder">
						<FormattedMessage defaultMessage={ "Řadit podle sloupce" } id={ "orderByColumn" } />
					</InputLabel>
					<Select labelId="labelOrder" value={ orderColumn } id="selectOrder"
							onChange={ ( event: React.ChangeEvent<{ name?: string; value: unknown }> ) => {
								setOrderColumn( event.target.value as string )
							} }>
						{ orderOptions }
					</Select>
				</FormControl>
				<FormControl style={ { width: "25%", alignItems: "center" } }>
					<ButtonGroup style={ { width: "90%", height: "2em" } } disableElevation variant="contained" color="primary">
						<Button onClick={ () => setOrderType( "DESC" ) } style={ { backgroundColor: orderType === "DESC" ? "#FC8C3F" : "#000" } }>
							<FontAwesomeIcon icon={ faSortAlphaDownAlt } />
						</Button>
						<Button onClick={ () => setOrderType( "ASC" ) } style={ { backgroundColor: orderType === "ASC" ? "#FC8C3F" : "#000" } }>
							<FontAwesomeIcon icon={ faSortAlphaUp } />
						</Button>
					</ButtonGroup>
				</FormControl>
			</div>
			<div style={ {
				display: "flex", alignItems: "center", justifyContent: "center", padding: "1em",
			} }>
				<Button onClick={ clearFilter }
						style={ {
							width: "1em",
							backgroundColor: "#FFF",
							border: "1px solid #ccc",
							borderRadius: "6px",
							minWidth: "3em",
							minHeight: "3em",
							marginRight: "1em",
						} }>
					<FontAwesomeIcon icon={ faTimes } />
				</Button>
				<Button onClick={ submit } style={ {
					width: "1em", backgroundColor: "#FC8C3F", color: "#FFF", minWidth: "3em", minHeight: "3em",
				} }>
					<FontAwesomeIcon icon={ faCheck } />
				</Button>
			</div>
			<Snackbar open={ open } autoHideDuration={ 3000 } onClose={ handleClose } sx={ { top: "500px" } }
					  anchorOrigin={ { horizontal: "center", vertical: "bottom" } }>
				<Alert severity="success" sx={ { width: '100%' } } onClose={ handleClose }>
					<FormattedMessage defaultMessage={ "Řazení bylo uloženo" } id={ "orderSaved" } />
				</Alert>
			</Snackbar>
		</>
	)
}