/* eslint-disable camelcase,func-names,no-var,no-restricted-syntax,no-prototype-builtins */
// @ts-nocheck
// noinspection SpellCheckingInspection,JSDeprecatedSymbols,EqualityComparisonWithCoercionJS,ES6ConvertVarToLetConst,JSUnusedGlobalSymbols,JSCheckFunctionSignatures,JSUnresolvedReference,OverlyComplexBooleanExpressionJS,JSFunctionExpressionToArrowFunction,JSUnusedGlobalSymbols,SpellCheckingInspection,TypeScriptUnresolvedReference

import { crmApi } from "../api/CrmApi"
import { AxiosInstance } from "axios"

class Application {
	data: { lang_data: Record<string, Record<string, string>>; onunload: CallableFunction[]; conf_data: Record<string, Record<string, string>>; onload: CallableFunction[] } = {
		lang_data: {},
		conf_data: {},
		onload: [],
		onunload: [],
	}

	isUnloaded = false

	/**
	 * extend object with new properties
	 * recursive on objects
	 */
	extend_object( destination, source ) {
		for( const property in source ) {
			if( typeof destination[ property ] == 'object' && typeof source[ property ] == 'object' ) {
				this.extend_object( destination[ property ], source[ property ] )
			} else {
				destination[ property ] = source[ property ]
			}
		}
	}

	/**
	 * extend object with new properties, non recursive
	 */
	extendObjectProperties( destination, source ) {
		for( const property in source ) {
			destination[ property ] = source[ property ]
		}
	}

	/**
	 * not recursive yet
	 */
	extendIfEmpty( destination, source ) {
		for( const property in source ) {
			if( typeof destination[ property ] == 'undefined' || destination[ property ] == null || destination[ property ] == '' ) {
				destination[ property ] = source[ property ]
			}
		}
	}

	escapeRegExp( str ) {
		return str.escapeRegExp()
	}

	get_lang( key: string, lang: string ) {
		try {
			lang = lang || this.lang
			return this.data.lang_data[ lang ][ key ] || key
		}
		catch( e ) {
			return ''
		}
	}

	get_config( group, key ) {
		try {
			let returnData
			if( group && key ) {
				returnData = this.data.conf_data[ group ][ key ]
			} else {
				returnData = this.data.conf_data[ group ]
			}
			if( typeof returnData == 'object' ) {
				if( returnData.length == 0 ) {
					returnData = {}
				} else { // aby se neprepisoval objekt s url parametry
					const newData = {}
					this.extendObjectProperties( newData, returnData )
					returnData = newData
				}
			}
			return returnData
		}
		catch( e ) {
			return ''
		}
	}

	getApi(): AxiosInstance {
		return crmApi
	}

	prepareFormData( options ) {
		$( $( '#' + options.form_id ).serializeArray() ).each( function() {
			if( typeof options.data[ this.name ] != 'undefined' && options.data[ this.name ] == '' ) { // check unneeded empty value
				delete options.data[ this.name ]
				// app.debug( 'delete empty ' + this.name )
			}
			if( typeof options.data[ this.name ] != 'undefined' && this.value == '' ) {
				// app.debug( 'ignore empty ' + this.name )
				return
			}

			if( typeof options.data[ this.name ] != 'undefined' && options.data[ this.name ] && this.value && options.data[ this.name ] != this.value ) { // multiple select
				if( typeof options.data[ this.name ] != 'object' ) {
					options.data[ this.name ] = [ options.data[ this.name ] ]
				}
				options.data[ this.name ].push( this.value )
			} else {
				options.data[ this.name ] = this.value
			}
		} )
		//todo vz debug log
		console.log( 'make form options : ' )
		console.log( options )
		return options.data
	}

	add_onload( fct ) {
		if( !this.data.onload ) {
			this.data.onload = []
		}
		this.data.onload.push( fct )
	}

	add_onunload( fct ) {
		if( !this.data.onunload ) {
			this.data.onunload = []
		}
		this.data.onunload.push( fct )
	}

	/**
	 * error message dialog
	 * @param {string} message message string
	 * @param {int} [timeout] in s
	 */
	error_message( message: string, timeout: int ) {
		app.closeDialog( '#multi_message' )
		const selector = "#error_message"
		$( selector ).html( message ).dialog( {
			//		buttons: [ {
			//			text: app.get_lang( 'close' ), click: function () {
			//				app.closeDialog( selector )
			//			}
			//		} ],
			show: { effect: "fadeIn", duration: 300 },
			hide: { effect: "fadeOut", duration: 100 },
			dialogClass: "user_message",
			width: 350,
			position: {
				my: "center top+20", at: "center top+20", of: window, collision: "flipfit",
			},
			open: function() {
				setTimeout( function() {
					$( ".ui-dialog-titlebar-close" ).blur()	 // @ts-ignore-line
				}, 850 )
			},
		} )
		setTimeout( function() {
			app.closeDialog( selector )
		}, timeout * 1000 || 60000 )
	}

	/**
	 * @param {string} message
	 * @param {int} [timeout]
	 */
	info_message( message: string, timeout: int ) {
		app.closeDialog( '#multi_message' )
		const selector = "#info_message"
		$( selector ).html( message ).dialog( {
			//		buttons: [ {
			//			text: "OK", click: function () {
			//				app.closeDialog( selector )
			//			}
			//		} ]
			show: { effect: "fadeIn", duration: 300 },
			hide: { effect: "fadeOut", duration: 100 },
			dialogClass: "user_message",
			width: 350,
			position: {
				my: "right-10 top+10", at: "right-10 top+10", of: window, collision: "flipfit",
			},
			open: function() {
				setTimeout( function() {
					$( ".ui-dialog-titlebar-close" ).blur()
				}, 850 )
			},
		} )
		setTimeout( function() {
			app.closeDialog( selector )
		}, timeout * 1000 || 3000 )
	}

	/**
	 * @param {string} message
	 * @param {int} [timeout]
	 */
	warning_message( message: string, timeout: int ) {
		app.closeDialog( '#multi_message' )
		const selector = "#warning_message"
		$( selector ).html( message ).dialog( {
			show: { effect: "fadeIn", duration: 300 },
			hide: { effect: "fadeOut", duration: 100 },
			dialogClass: "user_message",
			width: 350,
			position: {
				my: "center top+20", at: "center top+20", of: window, collision: "flipfit",
			},
			open: function() {
				setTimeout( function() {
					$( ".ui-dialog-titlebar-close" ).blur()
				}, 850 )
			},
		} )
		setTimeout( function() {
			app.closeDialog( selector )
		}, timeout * 1000 || 20000 )
	}

	/**
	 * error message dialog
	 * @param {string} message message string
	 * @param {int} [timeout] in s
	 */
	multi_message( message: string, timeout: int ) {
		const selector = "#multi_message"
		$( selector ).html( message ).dialog( {
			show: { effect: "fadeIn", duration: 800 },
			hide: { effect: "fadeOut", duration: 100 },
			dialogClass: "user_message",
			position: {
				my: "center top+20", at: "center top+20", of: window, collision: "flipfit",
			},
			width: "500px",
			open: function() {
				setTimeout( function() {
					$( ".ui-dialog-titlebar-close" ).blur()	 // @ts-ignore
				}, 850 )
			},
		} )
		setTimeout( function() {
			app.closeDialog( selector )
		}, timeout * 1000 || 60000 )
	}

	/**
	 * confirm message dialog
	 * @param {Object} options { action: "title", fct: fct, message: "message" }
	 */
	confirm( options: object ) {
		const selector = "#confirm_message"
		$( selector ).dialog( {
			bgiframe: true,
			resizable: false,
			modal: true,
			closeOnEscape: true,
			overlay: {
				backgroundColor: '#000',
				opacity: 0.4,
			},
			buttons: [ {
				text: options.action || app.get_lang( 'confirm_action' ),
				class: "btn btn-primary",
				click: function() {
					if( options.fct ) {
						options.fct()
					}
					app.closeDialog( selector )
					$( selector ).find( ".message" ).html( "" )
				},
			},
				{
					text: app.get_lang( "cancel" ),
					class: "btn btn-primary",
					click: function() {
						if( options.fctCancel ) {
							options.fctCancel()
						}
						app.closeDialog( selector )
						$( selector ).find( ".message" ).html( "" )
					},
				},
			],
		} ).find( ".message" ).text( options.message )
	}

	/**
	 * jquery ajax request with error handling
	 * @param {Object} options        { url:..,, success:..., type: 'post', dataType: 'json', form_id:..., input_selector:... }
	 * @param {string} container 	container id to display result
	 * @return {Object} jQuery ajax object
	 */
	getAjaxRequestOptions( options: object, container: string ): object {
		if( !options.url ) {
			app.error_message( app.get_lang( 'page_error' ) )
			return false
		}

		if( !options.data ) {
			options.data = {}
		}

		if( options.form_id ) { // form serialize
			$( $( '#' + options.form_id ).serializeArray() ).each( function() {
				if( typeof options.data[ this.name ] != 'undefined' && options.data[ this.name ] == '' ) { // check unneeded empty value
					delete options.data[ this.name ]
					app.debug( 'delete empty ' + this.name )
				}
				if( typeof options.data[ this.name ] != 'undefined' && this.value == '' ) {
					app.debug( 'ignore empty ' + this.name )
					return
				}

				if( typeof options.data[ this.name ] != 'undefined' && options.data[ this.name ] && this.value && options.data[ this.name ] != this.value ) { // multiple select
					if( typeof options.data[ this.name ] != 'object' ) {
						options.data[ this.name ] = [ options.data[ this.name ] ]
					}
					options.data[ this.name ].push( this.value )
				} else {
					options.data[ this.name ] = this.value
				}
			} )
			delete options.form_id
		}
		if( options.input_selector ) {
			const inputObj = $( options.input_selector )
			options.data[ inputObj.attr( 'name' ) ] = inputObj.val()
		}

		if( typeof options.data == 'string' ) {
			options.data += '&ajax_call=1'
		} else {
			options.data.ajax_call = 1		// tell server, it's ajax
		}

		if( options.formCallback ) {
			options.formCallback( options.data )
		}

		const success_callback = options.success
		const complete_options = $.extend( {
			type: options.type || 'POST',
			dataType: options.dataType || 'json',
			error: function( XMLHttpRequest, textStatus, errorThrown ) {
				if( app.isUnloaded ) {
					return
				}
				app.debug( XMLHttpRequest )
				var last = XMLHttpRequest
				app.debug( textStatus )
				app.debug( errorThrown )
				if( !( typeof options.global != 'undefined' && options.global == false ) ) { // only when global is true
					app.error_message( app.get_lang( 'page_error' ) )
				}
			},

		}, options )

		complete_options.success = function( data ) {
			//	app.debug( data );
			if( options.dataType && ( options.dataType == 'xml' || options.dataType == 'json' ) ) {
				if( success_callback ) {
					if( options.context ) {
						success_callback.call( options.context, data )
					} else {
						success_callback( data )
					}
				}

				if( options.successCallback ) { // after success
					options.successCallback()
				}
			} else {
				if( data && typeof data == "object" ) {
					if( !options.noErrorDialog ) {
						app.outputMessages( data )
					}

					// eslint-disable-next-line no-inner-declarations
					function reqest_callback() {
						if( data.content ) {
							container = container || data.container
							if( container ) {
								if( !data.errors && !data.warnings && !data.content.fields ) {
									$( container ).html( data.content )
								}
								if( data.title ) {
									const dialogParent = $( container ).parents( 'div.ui-dialog' )
									if( dialogParent.length > 0 && ( dialogParent.find( '.ui-dialog-title' ).text().trim() === '' ) ) {
										dialogParent.find( '.ui-dialog-title' ).html( data.title )
									}
								}
							}
							if( success_callback ) {
								if( options.context ) {
									success_callback.call( options.context, data.content )
								} else {
									success_callback( data.content )
								}
							}
						} else {
							if( success_callback ) {
								if( options.context ) {
									success_callback.call( options.context )
								} else {
									if( options.noErrorDialog ) {
										success_callback( data )
									} else {
										success_callback()
									}
								}
							}
						}
						if( data.javascript ) {
							app.globalEval( data.javascript )
						}
						if( options.successCallback ) {
							options.successCallback()
						}
					}

					// load additional files
					if( data.files || data.css ) {
						const loaderOptions = {
							success: reqest_callback,
						}
						if( data.files ) {
							loaderOptions.js = data.files
						}
						if( data.css ) {
							loaderOptions.css = data.css
						}
						// noinspection ObjectAllocationIgnored
						new LazyLoader( loaderOptions )
					} else {
						reqest_callback()
					}
				}
			}
		}

		return complete_options
	}

	outputMessages( data ) {
		let messagesCount = data.errors ? 1 : 0
		messagesCount += data.warnings ? 1 : 0
		messagesCount += data.infos ? 1 : 0

		if( messagesCount > 1 ) {
			let textMessage = ( data.errors ) ? '<div class="error_part">' + app.get_lang( 'error' ) + '<br/>' + data.errors + '</div>' : ''
			textMessage += ( data.warnings ) ? '<div class="warning_part">' + app.get_lang( 'warning' ) + '<br/>' + data.warnings + '</div>' : ''
			textMessage += ( data.infos ) ? '<div class="info_part">' + app.get_lang( 'info' ) + '<br/>' + data.infos + '</div>' : ''
			app.multi_message( textMessage )
		} else {
			data.errors && app.error_message( data.errors )
			data.warnings && app.warning_message( data.warnings )
			data.infos && app.info_message( data.infos )
		}
	}

	/**
	 * jquery ajax request with error handling
	 * @param {Object} options        { url:..,, success:..., type: 'post', dataType: 'json', form_id:..., input_selector:... }
	 * @param {string} [container]
	 */
	ajax_request( options: object, container: string ) {
		return $.ajax( app.getAjaxRequestOptions( options, container ) )
	}

	globalEval( data ) {
		window.eval.call( window, data )
	}

	debug( obj ) {
		if( app.get_config( 'output', 'debug' ) ) {
			if( typeof console != 'undefined' ) {
				console.log( obj )
			}
		}
	}

	redirect( url, timeout ) {
		if( timeout > 0 ) {
			setTimeout( 'window.top.location.href="' + url + '"', timeout * 1000 )
		} else {
			window.top.location.href = url
		}
	}

	openUrl( url, e ) {
		if( e.metaKey || e.ctrlKey || e.which === 2 ) {
			window.open()
		} else {
			location.href = url
		}
	}

	const

	global = {} // global context, like in php

	setInterval( command, seconds, name ) {
		if( name ) {
			if( global.intervalRegistry ) {
				if( global.intervalRegistry[ name ] ) {
					clearTimeout( global.intervalRegistry[ name ] )
					delete global.intervalRegistry[ name ]
				}
			}
		}
		const interval = seconds * 1000 || 1000
		const obj = setTimeout( command, interval )
		if( name ) {
			if( !global.intervalRegistry ) {
				global.intervalRegistry = []
			}
			global.intervalRegistry[ name ] = obj
		}
		return setInterval( command, interval )
	}

	setTimeout( command, seconds, name ) {
		if( name ) {
			if( global.timeoutRegistry ) {
				if( global.timeoutRegistry[ name ] ) {
					clearTimeout( global.timeoutRegistry[ name ] )
					delete global.timeoutRegistry[ name ]
				}
			}
		}
		const interval = seconds * 1000 || 1000
		const obj = setTimeout( command, interval )
		if( name ) {
			if( !global.timeoutRegistry ) {
				global.timeoutRegistry = []
			}
			global.timeoutRegistry[ name ] = obj
		}
		return obj
	}

	reload() {
		location.reload()
	}

	/**
	 *
	 * @param {string} action
	 * @param {Object} params
	 * @param {string} [plugin]
	 * @returns {string}
	 */
	get_url( action: string, params: object, plugin: string ): string {
		action = action || this.get_config( 'page', 'action' )
		plugin = plugin || this.get_config( 'page', 'plugin' )
		let url = this.get_config( 'page', 'base_path' ) + plugin + '/' + action + '?'
		if( typeof params == 'object' ) {
			for( const key in params ) {
				if( params.hasOwnProperty( key ) ) {
					url += key + '=' + encodeURIComponent( params[ key ] ) + '&'
				}
			}
		}
		return url
	}

	/**
	 *
	 * @param {string} url
	 * @param {Object} params
	 * @returns {string}
	 */
	makeUrl( url: string, params: object ): string {
		url += '?'
		if( typeof params === 'object' ) {
			url += this.paramsToString( params )
		}
		return url
	}

	/**
	 *
	 * @param action
	 * @param {Object} params
	 * @param plugin
	 * @returns {string}
	 */
	getUrl( action, params: object, plugin ): string {
		action = action || this.get_config( 'page', 'action' )
		plugin = plugin || this.get_config( 'page', 'plugin' )
		let url = this.get_config( 'page', 'base_path' ) + plugin + '/' + action + '?'
		if( typeof params === 'object' ) {
			url += this.serializeObjectToUrl( params )
		}
		return url
	}

	/**
	 *
	 * @param action
	 * @param {Object} params
	 * @param plugin
	 * @returns {string}
	 */
	getFullUrl( action, params: object, plugin ): string {
		action = action || this.get_config( 'page', 'action' )
		plugin = plugin || this.get_config( 'page', 'plugin' )
		let url = this.get_config( 'page', 'server_url' ) + '/' + plugin + '/' + action + '?'

		if( typeof params === 'object' ) {
			url += this.serializeObjectToUrl( params )
		}
		return url
	}

	isMobile(): boolean {
		return this.get_config( "output", "mobile" ) === 1
	}

	getCurrentUrl( params: object ): string {
		let url = window.location.href
		const pos = url.indexOf( '?' )
		if( pos ) {
			url = url.substring( 0, pos )
		}
		const currentParams = this.get_config( 'page', 'params' )
		if( params ) {
			app.extend_object( currentParams, params )
		}
		if( currentParams ) {
			url += '?' + this.paramsToString( params )
		}
		return url
	}

	paramsToString( params ): string {
		let key
		let url = ''
		for( key in params ) {
			if( params.hasOwnProperty( key ) ) {
				url += key + '=' + encodeURIComponent( params[ key ] ) + '&'
			}
		}
		return url
	}

	/**
	 *
	 * @param {object} obj
	 * @param {string} prefix
	 * @returns {string}
	 */
	serializeObjectToUrl( obj: object, prefix: string ): string {
		const str = []
		for( const key in obj ) {
			if( obj.hasOwnProperty( key ) ) {
				const k = prefix ? prefix + "[" + key + "]" : key
				const v = obj[ key ]
				str.push( typeof v == "object" ? this.serializeObjectToUrl( v, k ) : encodeURIComponent( k ) + "=" + encodeURIComponent( v ) )
			}
		}
		return str.join( "&" )
	}

	/**
	 * common dialog
	 * @param {string} url        url to load
	 * @param {Object} data        post data
	 * @param {Object} options    other options, see ui doc
	 * @param {string} [dialogId]
	 */
	dialog( url: string, data: object, options: object, dialogId: string ) {
		this.disableRefresh()

		if( !data ) {
			data = {}
		}
		if( !dialogId ) {
			dialogId = 'default_dialog'
		}
		const dialogSelector = '#' + dialogId

		if( typeof ( $( dialogSelector ).dialog( 'instance' ) ) != 'undefined' ) {
			$( dialogSelector ).dialog( 'destroy' )
		}

		data.source_plugin = this.get_config( 'page', 'plugin' )
		data.source_action = this.get_config( 'page', 'action' )

		$( dialogSelector + ' .contents' ).html( app.get_lang( 'loading' ) )

		$( '#form_hint' ).hide()

		options = options || {}

		if( options.width !== null && options.width === 0 ) {
			options.width = 1000
			const availWidth = window.innerWidth
			if( availWidth && availWidth > 1400 ) {
				options.width = 1350
			}
		}
		if( options.height !== null && options.height === 0 ) {
			const availHeight = window.innerHeight
			options.height = 600
			if( availHeight && availHeight > 850 ) {
				options.height = 800
			}
		}

		app.extendIfEmpty( options, { // default options
			modal: false,
			closeOnEscape: true,
			// position : 'center',
			draggable: true,
			title: options.title,
			width: 850,
			close: function() {
				$( dialogSelector ).css( "overflow", "auto" ).parents( ".ui-dialog" ).css( "overflow", "hidden" ) // reset default behavior
				app.closeDialog( dialogSelector )
				$( '#form_hint' ).hide()
				global.disableCursorKeys = false
			},
			open: function() {
				$( ".ui-dialog-titlebar-close" ).blur()
				const ajax_options = {
					data: data,
					url: url,
				}

				app.ajax_request( ajax_options, dialogSelector + ' .contents' )
			},
		} )
		if( !options.height && !options.minHeight ) {
			options.minHeight = 300
		}
		global.disableCursorKeys = true

		const dialog = $( dialogSelector ).dialog( options )
		if( options.overflow ) {
			dialog.css( "overflow", options.overflow ).parents( ".ui-dialog" ).css( "overflow", options.overflow )
		}
		return dialog
	}

	/**
	 * common dialog on to of the default dialog
	 * @param {string} url        url to load
	 * @param {Object} data        post data
	 * @param {Object} options    other options, see ui doc
	 */
	dialogTop( url: string, data: object, options: object ) {
		const dialogId = 'default_dialog_top'
		this.dialog( url, data, options, dialogId )
	}

	closeDialog( selector ) {
		selector = selector || '#default_dialog'
		const divObj = $( selector )
		if( typeof ( divObj.dialog( 'instance' ) ) != 'undefined' ) {
			divObj.dialog( 'close' ).find( '.contents' ).html( '' )
		}
	}

	destroyDialog( dialogSelector ) {
		if( typeof ( $( dialogSelector ).dialog( 'instance' ) ) != 'undefined' ) {
			$( dialogSelector ).dialog( 'destroy' )
		}
	}

	closeDialogTop() {
		this.closeDialog( '#default_dialog_top' )
	}

	disableRefresh() {
		$( "#meta_refresh" ).remove()
	}

	serializeForm( formId ) {
		const data = {}
		$( $( formId ).serializeArray() ).each( function() {
			data[ this.name ] = this.value
		} )
		return data
	}

	lang = 'cs'

	saveSetting( key, value ) {
		this.ajax_request( {
			url: app.get_url( 'saveSetting', {}, 'account' ),
			data: {
				key: key,
				value: value,
			},
			global: false,
		} )
	}

	cachedScriptPromises = {}

	getScript( url, callback ) {
		if( !Application.cachedScriptPromises[ url ] ) {
			Application.cachedScriptPromises[ url ] = $.Deferred( function( defer ) {
				$.ajax( {
					url: url,
					dataType: "script",
					cache: true,
					success: function() {
						app.debug( 'get script: ' + url )
					},
				} ).then( defer.resolve, defer.reject )
			} ).promise()
		}
		return Application.cachedScriptPromises[ url ].done( callback )
	}
}

const app: Application = new Application()

// @ts-ignore
window.Application = Application
window.app = app
window.global = global

export default app