import { TypographyOptions } from "@mui/material/styles/createTypography"

export const typographyOptions: TypographyOptions = {
	fontFamily: `"Roboto", "Open Sans", "Helvetica", "Arial", sans-serif`,
	// fontSize: 14,//16px
	// fontSize: 14,
	// fontSize: 13.125,//15px
	// fontSize: 12.25,//14px
	// fontSize: 11.375,//13px
	// fontSize: 10.5,//12px
	/// ///////////////////

	/// ///////h h h  h h h
	h1: {
		fontSize: "2.125rem",
		fontWeight: 600, // semibold
		// lineHeight: 'normal',
	},

	h2: {
		fontSize: "1.125rem", // 18px
		fontWeight: 600, // semibold
		// lineHeight: 3.1875,
	},
	h3: {
		fontSize: "1.0625rem", // 17px
		// fontWeight: 700,//bold
	},
	h4: {
		fontSize: "1rem", // 16px
		// fontWeight: 700,//bold
	},
	h5: {
		fontSize: "0.9375rem", // 15px
		fontWeight: 700, // bold
	},
	h6: {
		fontSize: "0.875rem", // 14px
		fontWeight: 700, // bold
	},
	body1: {
		fontSize: "0.8125rem", // 13px
		fontWeight: 400, // regular
		// lineHeight: 1.125,
	},
	subtitle1: {
		fontSize: "0.8125rem", // 13px
		fontWeight: 400, // regular
	},

	// 12px button
	overline: {
		fontSize: "0.75rem", // 12px
		fontWeight: 700, // bold
		// lineHeight: 3.1875,
	},

	body2: {
		fontSize: "0.6875rem", // 11px
		fontWeight: 700, // bold
	},
	subtitle2: {
		fontSize: "0.6875rem", // 11px
		fontWeight: 700, // bold
	},
	button: {
		fontSize: "0.6875rem", // 11px
		fontWeight: 700, // bold
		// lineHeight: 3.1875,
	},
	caption: {
		fontSize: "0.6875rem", // 11px
		// fontWeight: 700,//bold
	},
	tableHead: {
		fontSize: "0.6875rem",
		fontWeight: 700,

	},
	// todo_pb caption
}
