// noinspection SpellCheckingInspection

import * as React from "react"
import { Filter, IFilter, QuickFilter } from "./Filter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck"
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes"

type Props = {
	handleFilterChange: ( filter: IFilter ) => void,
	setFilterReload: ( reload: boolean ) => void,
	isReload: boolean,
	table: string
};

export const FilterFormNavBar = ( props: Props ) => {
	return (
		<>
			<button
				className={ " btn btn-primary btn btn-primary" }
				onClick={ () => {
					const filter = new Filter( props.table )
					filter.removeFromStorage()
					props.setFilterReload( !props.isReload )
					props.handleFilterChange( filter.filter )
				} }
			>
				<FontAwesomeIcon icon={ faTimes } />
			</button>
			<FilterButton key={ "top" } table={ props.table } handleFilterChange={ props.handleFilterChange } />
		</>
	)
}

type FilterButtonProps = { table: string, handleFilterChange: ( filter: IFilter ) => void };

const getFilterPostData = (): IFilter => {
	const data: Record<string, number | string | Array<string | number>> = {}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	$( $( "form.fform" ).serializeArray() ).each( ( _index: number, element: { value: string, name: string } ) => {
		if( typeof data[ element.name ] != "undefined" && data[ element.name ] != element.value ) { // multiple select
			if( typeof data[ element.name ] != "object" ) {
				// @ts-ignore
				data[ element.name ] = [ data[ element.name ] ]
			}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			// @ts-ignore
			data[ element.name ].push( element.value )
		} else {
			data[ element.name ] = element.value
		}
	} )
	return data as IFilter
}

const FilterButton = ( props: FilterButtonProps ) => {
	return (
		<>
			<button
				type={ "submit" }
				className={ "submit btn btn-primary btn btn-primary" }
				onClick={ () => {
					const filter = new Filter( props.table )
					filter.filter = getFilterPostData()
					filter.saveToStorage()
					const quickFilter = new QuickFilter( props.table )
					quickFilter.removeFromStorage()
					props.handleFilterChange( filter.filter )
				} }
			>
				<FontAwesomeIcon icon={ faCheck } />
			</button>
		</>
	)
}
