import * as React from "react"
import { useEffect, useState } from "react"
import MobileCrmData from "../MobileCrmData"
import ReactPlaceholder from "react-placeholder"
import "react-placeholder/lib/reactPlaceholder.css"
import SummaryListItem, { SummaryListData } from "./SummaryListItem"
import { fetchRawContentData } from "../form/FormDataHelper"
import { AxiosRequestConfig } from "axios"

export type RelationSummaryData = {
	name: string,
	tableId: number,
	count: number,
	icon: string,
	shortUrl: string,
	color: string
};
export type RelationSummaryProps = {
	table: string,
	id: number
}

const RelationSummary = ( { id, table }: RelationSummaryProps ) => {
	const [ relationData, setRelationData ] = useState<RelationSummaryData[] | null>( null )

	function fetchOptions( table: string, idNumeric: number ) {
		return {
			"params": {
				"id": idNumeric, "table": table,
			},
		} as AxiosRequestConfig
	}

	useEffect( () => {
		fetchRawContentData( MobileCrmData.RELATION_ACTION, fetchOptions( table, id ), setRelationData )
	}, [ table, id ] )

	return ( <ReactPlaceholder ready={ !!relationData } showLoadingAnimation>
		<RelationContainer data={ relationData } search={ fetchOptions( table, id ) } />
	</ReactPlaceholder> )
}

// The child doesn't trigger fetching anymore
function RelationContainer( { data, search }: {
	data: RelationSummaryData[] | null,
	search: Partial<AxiosRequestConfig>
} ) {
	return ( <ReactPlaceholder ready={ !!data } showLoadingAnimation>
			<div key={ "container" } className={ "relationSummaryContainer" }>
				{ data?.map( ( summary: RelationSummaryData, index: number ) => (
					<RelationSummaryItem key={ index } summaryData={ summary } search={ search } /> ) ) }
			</div>
		</ReactPlaceholder>
	)
}

const RelationSummaryItem = ( { summaryData, search }: {
	summaryData: RelationSummaryData,
	search: Partial<AxiosRequestConfig>
} ) => {
	const [ visible, setVisible ] = useState( false )
	const {
		icon, name, count,
	} = summaryData
	return ( <>
		<div
			className={ "relationSummaryItem" }
			onClick={ () => {
				setVisible( !visible )
			} }
		>
			<div className={ "relationName" }>
				<i className={ icon } />
				<span>{ name }</span>
			</div>
			<span className={ "relationCount" }>
				{ count }
				</span>
		</div>
		{ ( visible && count > 0 ) ? <RelationSummaryList search={ search } tableId={ summaryData.tableId } /> : "" }

	</> )
}

type SummaryListRowData = SummaryListData & {
	table: string
}

function RelationSummaryList( { search, tableId }: { search: Partial<AxiosRequestConfig>, tableId: number } ) {
	const [ relationListData, setRelationListData ] = useState<SummaryListRowData[] | null>( null )

	useEffect( () => {
		search.params = { ...search.params, tableId: tableId }
		fetchRawContentData( MobileCrmData.RELATION_LIST_ACTION, search, setRelationListData )
	}, [ search, tableId ] )

	return (
		<ReactPlaceholder ready={ !!relationListData } showLoadingAnimation>
			<div className={ "relationSummaryList" }>
				{ relationListData?.map( ( summaryRow: SummaryListRowData, i: number ) => ( <div key={ i } className={ "relationSummaryRow" }>
						<SummaryListItem data={ summaryRow } table={ summaryRow.table } />
					</div>

				) ) }
			</div>
		</ReactPlaceholder> )
}

export default RelationSummary
