import * as React from "react"
import { FormattedMessage } from "react-intl"
import Box from "@mui/material/Box"
import { Button } from "@mui/material"

type MobileConfirmDialogProps = {
	show: boolean,
	showCallback: ( setState: boolean ) => void,
	confirmCallback: () => void,
	confirmButtonText?: React.ReactNode,
	cancelButtonText?: React.ReactNode,
	message?: React.ReactNode
}

export const MobileConfirmDialog = ( props: MobileConfirmDialogProps ) => {
	const style = props.show ? "grid" : "none"
	return <div style={ { display: style } } className={ "mobileConfirmDialog" }>
		{ props.message }
		<Box style={ { display: "flex", justifyContent: "space-evenly", padding: "1em" } }>
			<Button variant="contained" color="primary" onClick={ () => {
				props.confirmCallback()
				props.showCallback( false )
			} }>
				{ props.confirmButtonText ? props.confirmButtonText : <FormattedMessage defaultMessage={ "Potvrdit" } id={ "confirm" } /> }
			</Button>
			<Button variant="outlined" color="primary" onClick={ () => {
				props.showCallback( false )
			} } sx={ { marginLeft: "1rem" } }>
				{ props.cancelButtonText ? props.cancelButtonText : <FormattedMessage defaultMessage={ "Zavřít" } id={ "close" } /> }
			</Button>
		</Box>
	</div>
}
