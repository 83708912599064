import axios, { AxiosInstance } from "axios"
import { BASE_API_URL } from "./BASE_URL"

// eslint-disable-next-line @typescript-eslint/restrict-template-expressions

const apiNoAuth: AxiosInstance = axios.create( {
	baseURL: BASE_API_URL,
	headers: {
		"Content-Type": "application/json;charset=utf-8",
		"Accept": "application/json",
	},
} )

export default apiNoAuth