// noinspection OverlyComplexBooleanExpressionJS

import * as React from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Menu, { MenuProps } from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus"
import MobileCrmData from "../MobileCrmData"
import { MenuData } from "../MainMenu/MainMenu"
import { MobileLinkFactory } from "../MobileLinkFactory"
import { Link } from "react-router-dom"
import MenuIcon from "../MainMenu/Component/MenuIcon"

const StyledMenu = withStyles( {
	paper: {
		border: "1px solid #d3d4d5",
	},
} )( ( props: MenuProps ) => (
	<Menu
		elevation={ 0 }
		getContentAnchorEl={ null }
		anchorOrigin={ {
			vertical: "center",
			horizontal: "center",
		} }
		transformOrigin={ {
			vertical: "center",
			horizontal: "center",
		} }
		{ ...props }
	/>
) )

const StyledMenuItem = withStyles( ( theme ) => ( {
	root: {
		"&:focus": {
			backgroundColor: "green",
			"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
				color: theme.palette.common.white,
			},
		},
	},
} ) )( MenuItem )

export default function FirmAddRelationButton( props: { firmDbId?: string, firmDbCustomerId?: string } ) {
	const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>( null )

	const handleClick = ( event: React.MouseEvent<HTMLElement> ) => {
		setAnchorEl( event.currentTarget )
	}

	const handleClose = () => {
		setAnchorEl( null )
	}
	const getItemUrl = ( shortUrl: string ) => {
		if( props.firmDbId != null ) {
			return shortUrl + "/" + MobileLinkFactory.FORM + "0/firmDbId/" + props.firmDbId	// todo firmDbId do api
		}
		return shortUrl + "/" + MobileLinkFactory.FORM + "0/firmCustomerId/" + props.firmDbCustomerId
	}

	const menuItems: MenuData[] = MobileCrmData.menuData.filter( ( item: MenuData ) => {
		if( item.shortUrl.indexOf( "firm" ) < 0 && item.shortUrl.indexOf( "logout" ) < 0 && item.shortUrl.indexOf( "home" ) < 0 && item.shortUrl.indexOf( "product" ) < 0 ) {
			return item
		}
		return undefined
	} )

	return (
		<div>
			<Button
				aria-controls="customized-menu"
				aria-haspopup="true"
				variant="contained"
				style={ { backgroundColor: "green", color: "#FFF" } }
				onClick={ handleClick }
			>
				<FontAwesomeIcon style={ { fontSize: "1.5em" } } icon={ faPlus } />
				<span style={ { paddingLeft: ".5em" } }>Přidat související</span>
			</Button>
			<StyledMenu
				id="customized-menu"
				anchorEl={ anchorEl }
				keepMounted
				open={ Boolean( anchorEl ) }
				onClose={ handleClose }
			>
				{
					menuItems.map( ( item: MenuData, i: number ) => (
						<StyledMenuItem key={ i }>
							<ListItemIcon key={ "menuList" + i }>
								<MenuIcon key={ "icon" + i } iconString={ item.icon } />
							</ListItemIcon>
							<Link to={ getItemUrl( item.shortUrl ) }>
								<ListItemText key={ i } primary={ item.name } />
							</Link>
						</StyledMenuItem>
					) )
				}
			</StyledMenu>
		</div>
	)
}
