import * as React from "react"
import { CSSProperties } from "react"
import { TaskData } from "../EntityType"
import MobileCrmData from "../MobileCrmData"
import { ListItemWrapper } from "../list/ListItemWrapper"
import { ListItemStateContent } from "../list/ListItemStateContent"

export type TaskDataProps = {
	data: TaskData, table: string,
	style: CSSProperties,
	index: number, onDeleteItemCallback: ( index: number ) => void
};

const ItemLeftSide = ( props: TaskDataProps ) => {
	return <>
		<div className={ "itemFirm ellipsis" }>
			<span dangerouslySetInnerHTML={ { __html: props.data.firmName } } />
		</div>
	</>
}
const ItemRightSide = ( props: TaskDataProps ) => {
	return <>
		{ props.data.dateFrom &&
			<div className={ "itemDateNextAction ellipsis" }>{ props.data.dateFrom }</div> }
		{
			props.data.permission.assignedUserId.map( ( userId, index ) => (
				<span key={ index }>
					{ MobileCrmData.getUserNameById( userId ) }
 				</span>
			) )
		}
	</>
}
const ItemBasic = ( props: TaskDataProps ) => {
	return <ListItemStateContent statusColor={ props.data.statusColor } statusName={ props.data.statusName } />
}

export const TaskListItem = ( props: TaskDataProps ) => {
	return <ListItemWrapper
		tagColor={ props.data.statusColor }
		name={ props.data.name } table={ props.table }
		permission={ props.data.permission } id={ props.data.id }
		itemExpandLeftElement={ <ItemLeftSide { ...props } /> }
		itemBasicElement={ <ItemBasic { ...props } /> }
		itemExpandRightElement={ <ItemRightSide { ...props } /> }
		style={ props.style } index={ props.index } onDeleteItemCallback={ props.onDeleteItemCallback } />
}
