import * as React from "react"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { PermissionData } from "../Permission"
import { MobileLinkFactory } from "../MobileLinkFactory"
import { DataLoader } from "@CrmCommon/loader/DataLoader"
import MobileCrmData from "../MobileCrmData"
import { MobileConfirmDialog } from "../dialog/MobileConfirmDialog"
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { FormattedMessage } from "react-intl"

export type ListItemMenuData = {
	permission?: PermissionData, table: string, id: number, index: number, onDeleteItemCallback: ( index: number ) => void,
	menuVisible: boolean, setMenuVisible: ( menuVisible: boolean ) => void
}

type EntityDeleteData = { deleted: number }

const ListItemMenu = ( props: ListItemMenuData ) => {
	const [ showDialog, setShowDialog ] = useState<boolean>( false )

	const canEdit = props.permission?.canEdit
	const canDelete = props.permission?.canEdit

	const confirmCallback = () => {
		const loader = new DataLoader().fetchData( MobileCrmData.getDeleteEntityUrl( props.id, props.table ) )
		loader.then( ( data: EntityDeleteData ) => {
			if( data.deleted == 1 ) {
				props.onDeleteItemCallback( props.index )
			}
		} )
	}
	return (
		<>
			<div className={ "itemMenu" } onClick={ () => {
				props.setMenuVisible( !props.menuVisible )
				setTimeout( () => {
					props.setMenuVisible( false )
				}, 4000 )
			} }>
				{ canEdit ? <>
					<FontAwesomeIcon icon={ faEllipsisV } />
					{ props.menuVisible &&
						<div className={ "menu" } style={ { display: "inline-grid" } }>
						<span>
							<Link
								key={ props.table }
								to={ MobileLinkFactory.createFormLink( props.table, props.id ) }
							>
								<FormattedMessage defaultMessage={ "Upravit" } id={ "listItemEdit" } />
							</Link>
						</span>
							{ canDelete ? <span
								onClick={ () => {
									setShowDialog( true )
								}
								}
							>
							<FormattedMessage defaultMessage={ "Smazat" } id={ "listItemDelete" } />
						</span> : undefined }
						</div>
					}
				</> : undefined }
			</div>

			<MobileConfirmDialog
				show={ showDialog }
				confirmCallback={ confirmCallback }
				showCallback={ setShowDialog }
				message={ <FormattedMessage defaultMessage={ "Opravdu si přejete smazat záznam? " } id={ "listItemDeleteConfirm" } /> }
				confirmButtonText={ <FormattedMessage defaultMessage={ "Smazat" } id={ "listItemDelete" } /> }
			/>
		</>

	)
}

export default ListItemMenu
