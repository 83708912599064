import * as React from 'react'
import { FormattedMessage } from "react-intl"

const UnknownPage = () => {
	return (
		<div>
			<FormattedMessage defaultMessage={ "Obsah nebyl nalezen" } id={ "pageNotFound" } />
		</div>
	)
}

export default UnknownPage