import * as React from "react"
import { DataLoader } from "@CrmCommon/loader/DataLoader"
import { unsetAuthTokens } from "./auth/unsetAuthTokens"

const LogoutPage = () => {
	const loader = new DataLoader()

	loader.fetchData( "logout" ).then( () => {
		unsetAuthTokens()
		document.location.replace( "/" )
	} ).catch( reason => {
		console.log( reason )
	} )

	return (
		<div />
	)
}

export default LogoutPage
