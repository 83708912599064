import * as React from "react"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

export default function PageSpinner() {
	return (
		<Backdrop open={ true }>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}
