import { createContext, PropsWithChildren, useContext, useState } from "react"

type MessageWrapperContextProps = {
    messageType: string;
    message: string;
    setMessageInfo:React.Dispatch<React.SetStateAction<{ messageType: string; message: string; }>>
}

const MessageWrapperContext = createContext<MessageWrapperContextProps>( {
	messageType: 'info', message: '', setMessageInfo: ()=>( { messageType: '', message: '' } ),
} )

export function MessageWrapperProvider( { children }: PropsWithChildren<any> ) {
	const [ { messageType, message }, setMessageInfo ] = useState( { messageType: 'info', message: '' } )
	return <MessageWrapperContext.Provider
		value={ {
			messageType, message, setMessageInfo,
		} }
	       >
		{ children }
	</MessageWrapperContext.Provider>
}

export const useMessageContext = () => useContext( MessageWrapperContext )
