import * as React from "react"
import { useState } from "react"
import { Box, TextField } from "@material-ui/core"
import { Autocomplete, createFilterOptions, Popper } from "@mui/material"
import MobileCrmData from "../MobileCrmData"
import { IFilter, QuickFilter } from "../form/Filter"
import QuickSearchInput from "../form/QuickSearchInput"

export type CountryOption = { name: string, code: string }
export type QuickFilterFormProps = { handleFilterChange: ( filter: IFilter ) => void, table: string, handleSearch: ( searchString: string ) => void };

export const FirmQuickFilterForm = ( props: QuickFilterFormProps ) => {
	const qFilter = new QuickFilter( props.table )
	let countryOption: CountryOption | undefined
	if( qFilter.filter.countryCode ) {
		countryOption = MobileCrmData.getCountryData().find( ( country ) => country.code === qFilter.filter.countryCode )
	}
	if( !countryOption ) {
		countryOption = {
			"code": "CZ",
			"name": "Česká republika",
		}
	}
	const [ selectedCountry, setSelectedCountry ] = useState<CountryOption>( countryOption )

	function countryToFlag( isoCode: string ) {
		return typeof String.fromCodePoint === "undefined" ? isoCode : isoCode
			.toUpperCase()
			.replace( /./g, ( char ) => String.fromCodePoint( char.charCodeAt( 0 ) + 127397 ) )
	}

	const onSearchSubmit = ( searchString: string ) => {
		props.handleSearch( searchString )
	}

	return (
		<>
			<QuickSearchInput onSearchSubmit={ onSearchSubmit } />
			<Box sx={ {
				flex: 1, flexGrow: 0, m: 1,
			} }>
				<Autocomplete<CountryOption, false, true>
					filterOptions={ createFilterOptions( {
						matchFrom: "start",
						stringify: option => option.name,
					} ) }
					value={ selectedCountry }
					isOptionEqualToValue={ ( option, value ) => option.code === value.code }
					disableClearable
					options={ MobileCrmData.getCountryData() }
					onChange={ ( _event: React.SyntheticEvent, newValue: CountryOption | null ) => {
						if( newValue ) {
							setSelectedCountry( newValue )
							const quickFilter = new QuickFilter( props.table )
							quickFilter.filter.countryCode = newValue.code
							quickFilter.saveToStorage()

							localStorage.removeItem( props.table + "Filter" )
							props.handleFilterChange( quickFilter.filter )
						}
					} }
					PopperComponent={ ( props ) => {
						return <Popper { ...props } style={ { width: "100%", padding: "1rem 0", height: "auto" } } />
					} }
					renderInput={ ( params ) => (
						<TextField
							{ ...params }
							inputProps={ {
								...params.inputProps,
								autoComplete: "new-password", // disable autocomplete and autofill
							} }
						/>
					) }
					renderOption={ ( props, countryOption: CountryOption ) => {
						return <Box component="li" key={ "flag" + countryOption.code } { ...props }>
							{ countryToFlag( countryOption.code ) }
							<span style={ { marginLeft: "1rem" } }>
							{ countryOption.name }
							</span>
						</Box>
					} }
					getOptionLabel={ ( option: CountryOption ) => countryToFlag( option.code ) }
				/>
			</Box>
		</>
	)
}
