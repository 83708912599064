import { createContext, ReactNode, useContext } from "react"
import { AxiosInstance } from "axios"
import api from "./api"
import apiNoAuth from "./apiNoAuth"
import { crmApi } from "./CrmApi"

export const DHO_CLIENT_TOKEN = "Dho-Client-Token"
export const DHO_ACCESS_TOKEN = "Dho-access-token"
export const DHO_REQUEST_TOKEN = "Dho-Request-Token"

export type ApiResponse ={
	errors?:{
		code?:string,
		message?:string,
		violations?:[]
	}
}
export enum ApiTypes {
	api,   // defaultValue
	apiNoAuth,
	crmApi,
}

const ApiContext = createContext<AxiosInstance>( api )

type ApiProviderProps =  {
	children: ReactNode
	apiType: ApiTypes
}

function getApi( apiType: ApiTypes ) {
	switch( apiType ) {
	case ( ApiTypes.api ):
		return api
	case ( ApiTypes.apiNoAuth ):
		return apiNoAuth
	case ( ApiTypes.crmApi ):
		return crmApi
	}
}

export function ApiProvider( { apiType, children }: ApiProviderProps ) {
	return <ApiContext.Provider value={ getApi( apiType ) }>
		{ children }
	</ApiContext.Provider>
}

export const useApi = () => useContext( ApiContext )
