import * as React from "react"
import { Link } from "react-router-dom"
import { MenuData } from "../MainMenu"

interface MainMenuItemProps {
	item: MenuData,
	autoHideOnClickHandler: () => void
}

export class MainMenuItem extends React.PureComponent<MainMenuItemProps> {
	render(): React.ReactNode {
		const { item, autoHideOnClickHandler } = this.props
		const { shortUrl, name } = item
		if( !shortUrl ) {
			console.error( 'Missing shortUrl', item )
			return null
		}
		return (
			<li>
				<Link
					onClick={ () => {
						autoHideOnClickHandler()
						document.title = name
					} }
					to={ shortUrl }
				>
					{ name }
				</Link>
			</li>
		)
	}
}