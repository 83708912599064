import * as React from "react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AxiosRequestConfig } from "axios"
import { FirmDbCountryData } from "../EntityType"
import MobileCrmData from "../MobileCrmData"
import { NoDataWrapper } from "../NoDataWrapper"
import RelationSummary from "../summary/RelationSummary"
import { PersonViewWrapper } from "../person/PersonViewWrapper"
import { ContactViewWrapper } from "../contact/ContactViewWrapper"
import { FirmBasicInfoDetail } from "../detail/FirmBasicInfoDetail"
import { fetchRawContentData } from "../form/FormDataHelper"
import { EntityTabs } from "../form/Form"
import Box from "@mui/material/Box"
import { TabPanel } from "../helper/TabPanel"
import FormTopBar from "../form/FormTopBar"
import { makeTabDefinition } from "../form/FormPanels"

export const MobileDetailView = () => {
	const { table, id } = useParams() as { table: string, id: string }
	const idNumeric: number = parseInt( "" + id )
	const [ detailData, setDetailData ] = useState<FirmDbCountryData[]>( [] )
	const [ value, setValue ] = React.useState( 0 )

	function fetchOptions( table: string, idNumeric: number ) {
		return {
			"params": {
				"id": idNumeric,
				"table": table,
			},
		} as AxiosRequestConfig
	}

	useEffect( () => {
		fetchRawContentData( MobileCrmData.DETAIL_VIEW_ACTION, fetchOptions( table as string, idNumeric ), setDetailData )
	}, [ table, idNumeric ] )

	const tabs = makeTabDefinition( table, idNumeric )

	return (
		<>
			<FormTopBar table={ table } id={ idNumeric } />
			<Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
				<EntityTabs value={ value } tabs={ tabs } setValue={ setValue } />
			</Box>
			<TabPanel index={ 0 } value={ value } table={ table }>
				<FirmBasicInfoDetail detailData={ detailData && detailData.length > 0 ? detailData[ 0 ] : undefined } />
			</TabPanel>
			<TabPanel index={ 1 } value={ value } table={ table }>
				{ Number.parseInt( id ) == 0 ? <NoDataWrapper /> : <RelationSummary table={ table } id={ Number.parseInt( id ) } /> }
			</TabPanel>
			<TabPanel index={ 2 } value={ value } table={ table }>
				<PersonViewWrapper table={ table } id={ idNumeric } />
			</TabPanel>
			<TabPanel index={ 3 } value={ value } table={ table }>
				<ContactViewWrapper table={ table } id={ idNumeric } />
			</TabPanel>
		</>
	)
}
