import * as React from "react"
import { CSSProperties } from "react"
import { EntityBasicData } from "../EntityType"
import MobileCrmData from "../MobileCrmData"
import { ListItemWrapper } from "./ListItemWrapper"

export type ListItemDataProps = {
	data: EntityBasicData, table: string,
	style: CSSProperties,
	index: number, onDeleteItemCallback: ( index: number ) => void
};

const ItemLeftSide = ( props: ListItemDataProps ) => {
	return <>
		<div className={ "itemFirm" }>
			<span dangerouslySetInnerHTML={ { __html: props.data.firmName } } />
		</div>
	</>
}
const ItemRightSide = ( props: ListItemDataProps ) => {
	return <>
		{
			props.data.permission.assignedUserId.map( ( userId, index ) => (
				<span key={ index }>
					{ MobileCrmData.getUserNameById( userId ) }
 				</span>
			) )
		}
	</>
}

const ListItem = ( props: ListItemDataProps ) => {
	return (
		<ListItemWrapper
			name={ props.data.name } table={ props.table }
			permission={ props.data.permission } id={ props.data.id }
			itemExpandLeftElement={ <ItemLeftSide { ...props } /> }
			itemExpandRightElement={ <ItemRightSide { ...props } /> }
			style={ props.style } index={ props.index } onDeleteItemCallback={ props.onDeleteItemCallback } />
	)
}

export default ListItem
