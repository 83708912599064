import Toolbar from "@mui/material/Toolbar"
import { AppBar } from "@mui/material"
import { Box } from "@material-ui/core"
import ListTopBarMenu from "../list/ListTopBarMenu"
import { ReactNode } from "react"
import { ListNavBarTitle } from "../CrmTopNavigationBar"

const MobilePageViewTop = ( props: { table: string, children?: ReactNode } ) => (
	<Box sx={ { flexGrow: 1 } }>
		<AppBar position="static" color={ "secondary" } sx={ { backgroundColor: "#1B1212" } }>
			<Toolbar>
				{ props.children }
				<ListNavBarTitle />
				<ListTopBarMenu table={ props.table } />
			</Toolbar>
		</AppBar>
	</Box>
)

export default MobilePageViewTop