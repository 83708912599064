// @ts-nocheck

import * as React from "react"
import { AutoSizer, CellMeasurer, CellMeasurerCache, IndexRange, InfiniteLoader, List, ListRowProps } from "react-virtualized"
import { ListItemFactory } from "./ListItemFactory"
import { EntityBasicData } from "../EntityType"
import ReactPlaceholder from "react-placeholder"
import "react-placeholder/lib/reactPlaceholder.css"
import { ListDeleteCallback } from "./MobileListDataWrapper"

export type MobileListViewProps = {
	table: string,
	listData: EntityBasicData[]

	handleFetchFeed: ( range: IndexRange ) => Promise<void>,
	deleteCallback: ListDeleteCallback
}

export const REQUEST_THRESHOLD = 15

let lastRequestedIndex: number

export function MobileListView( props: MobileListViewProps ) {
	const cellMeasurerCache: CellMeasurerCache = new CellMeasurerCache( {
		fixedWidth: true,
		defaultHeight: 80,
	} )

	const isRowLoaded = ( index: number ): boolean => {
		return props.listData[ index ] !== undefined
	}

	return <>
		<div className="InfinteList" style={ { height: "100vh" } }>
			<ReactPlaceholder
				ready={ props.listData.length > 0 }
				type={ "text" }
				rows={ REQUEST_THRESHOLD }
				showLoadingAnimation={ true }
			>
				<InfiniteLoader
					minimumBatchSize={ REQUEST_THRESHOLD }
					isRowLoaded={ ( index ) => isRowLoaded( index.index ) }
					loadMoreRows={ ( range: IndexRange ): Promise<unknown> => {
						if( props.listData.length > 0 && props.listData.length <= ( REQUEST_THRESHOLD / 2 ) ) {
							return Promise.resolve()
						}

						if( lastRequestedIndex != null && ( range.startIndex - lastRequestedIndex ) < ( REQUEST_THRESHOLD / 2 ) ) {
							console.log( "request blocked" )
							return Promise.resolve()
						}

						lastRequestedIndex = range.startIndex

						return props.handleFetchFeed( range )
					} }
					rowCount={ 10000000 }
					// threshold={ REQUEST_THRESHOLD }
				>
					{ ( { onRowsRendered } ) => (
						<AutoSizer>
							{ ( { width, height } ) => (
								<List
									rowCount={ props.listData.length }
									width={ width }
									height={ height }
									rowHeight={ cellMeasurerCache.rowHeight }
									rowRenderer={ ( listRowProps: ListRowProps ) => {
										return <RowRenderer
											key={ listRowProps.index }
											cellMeasurerCache={ cellMeasurerCache }
											listData={ props.listData }
											listRowProps={ listRowProps }
											onDeleteItemCallback={ props.deleteCallback }
											table={ props.table }
										/>
									}
									}
									deferredMeasurementCache={ cellMeasurerCache }
									// overscanRowCount={ 8 }
									onRowsRendered={ onRowsRendered }
								/>
							) }
						</AutoSizer>
					) }
				</InfiniteLoader>
			</ReactPlaceholder>
		</div>
	</>
}

type RowRendererProps = {
	listRowProps: ListRowProps,

	table: string
	listData: EntityBasicData[],
	cellMeasurerCache: CellMeasurerCache,

	onDeleteItemCallback( index: number ): void
};

function RowRenderer( props: RowRendererProps ) {
	const entityData = props.listData[ props.listRowProps.index ]
	return <CellMeasurer
		key={ props.listRowProps.key }
		cache={ props.cellMeasurerCache }
		parent={ props.listRowProps.parent }
		columnIndex={ 0 }
		rowIndex={ props.listRowProps.index }
	>
		<ListItemFactory
			data={ entityData }
			table={ entityData.entityTable ?? props.table }
			style={ props.listRowProps.style }
			index={ props.listRowProps.index }
			onDeleteItemCallback={ props.onDeleteItemCallback }
		/>
	</CellMeasurer>
}