import * as React from "react"
import { ContactItemMenuView } from "./ContactItemMenuView"
import { ContactData } from "../EntityType"

type ContactViewProps = {
	contactData: ContactData,
	showEdit: boolean
};

export function ContactItemView( props: ContactViewProps ) {

	return (
		<div className={ "contactItem" }>
			<div>{ props.contactData.contact }</div>
			<ContactItemMenuView showEdit={ props.showEdit } contactData={ props.contactData } />
		</div>
	)
}