import * as React from "react"

export interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
	table: string
}

export function TabPanel( props: TabPanelProps ) {
	const {
		children, value, index, table, ...other
	} = props

	return (
		<div
			role="tabpanel"
			hidden={ value !== index }
			id={ `main-tab-panel-${ index }` }
			key={ `main-tab-panel-${ table }-${ index }` }
			style={ { overflowY: "scroll", height: "85vh" } }
			{ ...other }
		>
			{ value === index && (
				children
			) }
		</div>
	)
}