/// <reference path="../../submodule/core/js/application.js" />

import { ComponentList } from "../../submodule/core/js/componentList/componentList"
import { IFormObject } from "../form/IFormObject"
import { FirmDetail } from "../firm/firmDetail"

type ajaxResponseData = {
	content: any,
	errors: string,
};

export class RelationSelectModule {

	//main entity identifier encrypted
	private readonly tableId: string = null
	private readonly entityId: string = null

	constructor( tableId: string, entityId: string ) {
		this.tableId = tableId
		this.entityId = entityId
	}

	public showFormRelationSelectList( formId: string, formToken: string, url: string, title: string, relationTableId: string ): void {
		console.log( "load form relation list for " + relationTableId )
		const dialogIdEscaped = RelationSelectModule.makeDialog()
		app.dialog( url, {
			tableId: this.tableId,
			entityId: this.entityId,
			relationTableId: relationTableId,
			parentForm: formId,
			parentFormToken: formToken,
		}, this.createDialogOptions( title, dialogIdEscaped ), dialogIdEscaped )
	}

	public showFirmDetailRelationSelectList( url: string, title: string, relationTableId: string ): void {
		console.log( "load firm relation list for " + relationTableId )
		const dialogIdEscaped = RelationSelectModule.makeDialog()
		app.dialog( url, {
			tableId: this.tableId,
			entityId: this.entityId,
			relationTableId: relationTableId,
		}, this.createDialogOptions( title, dialogIdEscaped ), dialogIdEscaped )
	}

	private createDialogOptions( title: string, dialogIdEscaped: string ): { width: number; title: string; close: () => void; height: number } {
		return {
			title: title,
			width: 1600,
			height: 800,
			close: function () {
				const selector: string = "#" + dialogIdEscaped
				app.destroyDialog( selector )
				$( selector ).remove()
			},
		}
	}

	public saveRelationSelection( thisButton: HTMLButtonElement, listId: string, parentFormId: string ): void {
		const list: ComponentList = window[ listId ]
		const parentForm: IFormObject = parentFormId ? window[ parentFormId ] : null
		if( list === undefined ) {
			console.log( "Invalid list object " + listId )
		}
		let firmChange = false
		const button = $( thisButton )
		const firmChangeCheckbox = button.parents( ".relationListSelectButtons" ).find( "#firmChange" )
		if( firmChangeCheckbox.length ) {
			firmChange = firmChangeCheckbox.prop( "checked" )
		}

		const _this: RelationSelectModule = this
		const params = {
			"data": {
				"list_action": "saveRelationSelection",
				"list_id": listId,
				"instance_token": list.instanceToken,
				"relationIds": list.getSelectedIds(),
				"firmChange": firmChange,
			},
			dataType: "json",
			"url": list.getListUrl(),
			success: function ( data: ajaxResponseData ) {
				if( data.errors ) {
					app.error_message( data.errors )
					return
				}
				_this.closeListDialog( thisButton )
				//reload source
				if( parentForm ) {
					//reload parent form
					parentForm.reload()
				} else {
					const firmDetail: FirmDetail = window[ "firmDetailModule" ]
					if( firmDetail ) {
						//reload firm detail relation info
						firmDetail.loadBasicRelationInfo()
					}
				}

				//unset list object
				delete window[ listId ]
				delete window[ listId + "items" ]
			},
		}
		app.ajax_request( params )
	}

	closeListDialog( thisButton: HTMLButtonElement ) {
		const button = $( thisButton )
		const dialog = button.parents( ".relationSelectListDialog" ).first()
		app.destroyDialog( dialog )
	}

	private static makeDialog(): string {
		const rand = Math.floor( ( Math.random() * 10000 ) + 1 )
		const newDialogId = "relationSelectListDialog" + rand.toFixed()
		$( "body" ).append( $( "<div id=\"" + newDialogId + "\" class=\"relationSelectListDialog\" style=\"display:none;\"><div class=\"contents\"></div></div>" ) )
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return app.escapeRegExp( newDialogId )
	}
}