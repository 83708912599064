import { FormatNumberOptions } from "react-intl"

export type PriceOptions = {
	opts: Omit<FormatNumberOptions, 'style'>
}

export const DEFAULT_PRICE_OPTIONS: PriceOptions = {
	opts: {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
		currencyDisplay: "code",
	},
}
