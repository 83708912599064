import * as React from "react"
import { IFilter } from "./Filter"
import { FIRM_GLOBAL_VIEW } from "../helper/ViewHelper"
import Box from "@mui/material/Box"
import { FirmQuickFilterForm } from "../firm/FirmQuickFilterForm"
import { DefaultQuickFilterForm } from "./DefaultQuickFilterForm"

export type CountryOption = { name: string, code: string }
export type QuickFilterFormProps = { handleFilterChange: ( filter: IFilter ) => void, table: string, handleSearch: ( searchString: string ) => void };

export const QuickFilterForm = ( props: QuickFilterFormProps ) => {
	return (
		<Box sx={ { display: "flex", flexDirection: "row", padding: "0.5rem" } }>
			{ props.table == FIRM_GLOBAL_VIEW
				? <FirmQuickFilterForm handleFilterChange={ props.handleFilterChange } table={ props.table } handleSearch={ props.handleSearch } />
				: <DefaultQuickFilterForm handleFilterChange={ props.handleFilterChange } table={ props.table } handleSearch={ props.handleSearch } />
			}
		</Box>
	)
}
