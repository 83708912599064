import * as React from "react"
import { ReactNode, useEffect, useState } from "react"
import { ContactData, TYPE_MAIL, TYPE_PHONE, TYPE_WEB } from "../EntityType"
import { ContactItemView } from "./ContactItemView"
import { FormattedMessage } from "react-intl"
import { NoDataWrapper } from "../NoDataWrapper"
import { fetchOptions, fetchRawContentData } from "../form/FormDataHelper"
import MobileCrmData from "../MobileCrmData"
import { FormDataType } from "../form/FormPanels"

export const ContactViewWrapper = ( { firmDbId, id, table }: FormDataType ) => {
	const [ contactData, setContactData ] = useState<ContactData[] | null>( null )
	const [ loading, setLoading ] = useState<boolean>( true )

	useEffect( () => {
		fetchRawContentData( MobileCrmData.CONTACT_LIST_ACTION, fetchOptions( table, id, firmDbId ), setContactData )
		setLoading( false )
	}, [ table, id, firmDbId ] )

	const phone: ReactNode[] = []
	const mail: ReactNode[] = []
	const web: ReactNode[] = []

	contactData && contactData.forEach( ( contact: ContactData, index: number ) => {
		const contactView = <ContactItemView showEdit={ true } key={ index } contactData={ contact } />
		switch( contact.contactType ) {
			case TYPE_PHONE:
				phone.push( contactView )
				break
			case TYPE_WEB:
				web.push( contactView )
				break
			case TYPE_MAIL:
				mail.push( contactView )
				break
		}
	} )

	return (
		<div>
			{ loading === false && contactData !== null && !contactData.length && <NoDataWrapper /> }
			{
				phone.length > 0 ? <div className={ "personBox" }>
					<div className={ "personName" }><FormattedMessage defaultMessage={ "Telefon" } id={ "contactTypePhone" } /></div>
					{ phone }
				</div> : null
			}
			{
				mail.length > 0 ? <div className={ "personBox" }>
					<div className={ "personName" }><FormattedMessage defaultMessage={ "Mail" } id={ "contactTypeEmail" } /></div>
					{ mail }
				</div> : null
			}
			{
				web.length > 0 ? <div className={ "personBox" }>
					<div className={ "personName" }><FormattedMessage defaultMessage={ "Web" } id={ "contactTypeWeb" } /></div>
					{ web }
				</div> : null
			}
		</div>
	)
}
