import * as React from "react"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner"
import MobileCrmData from "./MobileCrmData"

type MobileTemplateProps = { children: React.ReactNode }

export const MobileTemplate: React.FC<MobileTemplateProps> = ( props: MobileTemplateProps ) => {
	const [ loading, setLoading ] = useState( true )
	useEffect( () => {
		if( loading ) {
			MobileCrmData.loadData().then( MobileCrmData.loadMenuData )
				.then( () => {
					setLoading( false )
				} )
				.catch( ( error: Error ) => {
					console.log( error )
					setLoading( false )
				} )
		}
	}, [ loading ] )

	$( document ).on( "turbolinks:before-cache", () => {     // this approach corrects the select 2 to be duplicated when clicking the back button.
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		$( ".select-select2" ).select2( "destroy" )

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		$( ".select-search-select2" ).select2( "destroy" )
	} )

	return (
		<>
			{ loading ?
				<div style={ {
					display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", height: "100vh",
				} }>
					<FontAwesomeIcon icon={ faSpinner } spin size={ "8x" } />
				</div>
				:
				props.children }
		</>

	)
}