import { IAuthTokens } from "./IAuthTokens"

export const setAuthTokens = ( authTokens: IAuthTokens ) => {
	if( authTokens.requestToken && authTokens.loginToken ) {
		const dataToSave: ( string | number )[] = [
			authTokens.requestToken?.token,
			authTokens.requestToken?.expiration,
			authTokens.loginToken?.token,
			authTokens.loginToken?.expiration,
		]
		localStorage.setItem( "token", dataToSave.join( "|" ) )
	}
}