import * as React from "react"
import { ContactData } from "../EntityType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil"
import MobileCrmData from "../MobileCrmData"

type EditUrlParams = {
	id: number,
	firmDbId?: number,
	firm_db_id_cust?: number,
	subobjectList?: string,
	is_in_dialog: number
}

type ContactEditButtonProps = {
	contactData: ContactData
}

export const ContactEditButton = ( props: ContactEditButtonProps ) => {

	if( props.contactData.permission.canEdit === false ) {
		return ( <></> )
	}

	const urlParams: EditUrlParams = {
		id: props.contactData.id,
		is_in_dialog: 1,
	}
	let urlAction = "firmSubobjectEdit"

	if( props.contactData.firmCustomerId ) {
		urlParams.firm_db_id_cust = props.contactData.firmCustomerId
		urlParams.subobjectList = "contact_list"
	} else {
		urlParams.firmDbId = props.contactData.firmDbId
		urlAction = "firmContactEntryEdit"
	}
	return ( <a onClick={ () => {
			const rand = Math.floor( ( Math.random() * 10000 ) + 1 )
			const newDialogId: string = "mobile_dialog" + rand.toString()
			$( "body" ).append( $( "<div id=\"" + newDialogId + "\" class=\"newEntityDialog\" style=\"display:none;\"><div class=\"contents\"></div></div>" ) )
			app.dialog( MobileCrmData.serverUrl + "/firm/" + urlAction + "?mobile=1", urlParams, {
				title: "Upravit kontakt",
				width: 950,
				height: 650,
				close: function() {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/restrict-plus-operands
					app.destroyDialog( "#" + newDialogId.escapeRegExp() )
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/restrict-plus-operands
					$( "#" + newDialogId.escapeRegExp() ).remove()
				},
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			}, newDialogId.escapeRegExp() )
		} }><FontAwesomeIcon icon={ faPencil } /></a>
	)
}