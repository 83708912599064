import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone"
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope"
import { faCopy } from "@fortawesome/pro-solid-svg-icons/faCopy"
import { faGlobe } from "@fortawesome/pro-solid-svg-icons/faGlobe"
import { ContactData } from "../EntityType"
import { ContactEditButton } from "./ContactEditButton"

type ContactViewItemMenuProps = {
	showEdit: boolean,
	contactData: ContactData

};

export function ContactItemMenuView( props: ContactViewItemMenuProps ) {
	const TYPE_MOBILE = "mobile"
	const TYPE_PHONE = "phone"
	const TYPE_MAIL = "email"
	const TYPE_MESSENGER = "im"
	const TYPE_WEB = "www"

	let menuItem: JSX.Element = <></>

	switch( props.contactData.contactType ) {
	case TYPE_MOBILE:
		menuItem = <a href={ "tel:" + props.contactData.contact }>
			{' '}
			<FontAwesomeIcon icon={ faPhone } />
		</a>
		break
	case TYPE_PHONE:
		menuItem = <a href={ "tel:" + props.contactData.contact }>
			{' '}
			<FontAwesomeIcon icon={ faPhone } />
		</a>
		break
	case TYPE_MAIL:
		menuItem = <a href={ "mailto:" + props.contactData.contact }>
			{' '}
			<FontAwesomeIcon icon={ faEnvelope } />
		</a>
		break
	case TYPE_WEB:
		menuItem = <a href={ "https://" + props.contactData.contact }>
			{' '}
			<FontAwesomeIcon icon={ faGlobe } />
		</a>
		break
	case TYPE_MESSENGER:
		menuItem = <a
			onClick={
				() => {
					const dummy = document.createElement( "textarea" )
					document.body.append( dummy )
					dummy.value = props.contactData.contact
					dummy.select()
					document.execCommand( "copy" )
					dummy.remove()
				}
			}
		           >
			{' '}
			<FontAwesomeIcon icon={ faCopy } />
		</a>
		break
	}

	return (
		<div className={ "contactItemMenu" }>
			{ menuItem }
			{ props.showEdit ? <ContactEditButton contactData={ props.contactData } /> : null }
		</div>
	)
}