import Toolbar from "@mui/material/Toolbar"
import { AppBar, IconButton } from "@mui/material"
import { Box } from "@material-ui/core"
import * as React from "react"
import { ReactNode } from "react"
import { ListNavBarTitle } from "../CrmTopNavigationBar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft"
import { useNavigate } from "react-router-dom"
import { MobileLinkFactory } from "../MobileLinkFactory"

const FormTopBar = ( props: { table: string, id: number, children?: ReactNode } ) => {
	const navigate = useNavigate()
	return (
		<Box sx={ { flexGrow: 1 } }>
			<AppBar position="static" color={ "secondary" } sx={ { backgroundColor: "#1B1212" } }>
				<Toolbar>
					<IconButton
						size="large"
						color="inherit"
						edge="start"
						sx={ { mr: 2 } }
						onClick={ () => navigate( MobileLinkFactory.createListLink( props.table ) ) }
					>
						<FontAwesomeIcon icon={ faChevronLeft } />
					</IconButton>
					{ props.children }
					<ListNavBarTitle />
					<Box sx={ { flexGrow: 1, textAlign: "right" } } id="formTopButtons" />
				</Toolbar>
			</AppBar>
		</Box>
	)
}

export default FormTopBar