import { IntlProvider } from "react-intl"
import * as React from "react"
import { useState } from "react"
import English from "../../../lang/compiled/en.json"
import Czech from "../../../lang/compiled/cs.json"

export function DHOIntlProvider( props: { children: React.ReactNode } ) {
	const browserLang = navigator.language.split( "-" )[ 0 ]
	const [ messages ] = useState( browserLang === "en" ? English : Czech )

	return <IntlProvider defaultLocale="cs" locale={ browserLang } messages={ messages } onError={ err => console.log( err ) }>
		{ props.children }
	</IntlProvider>
}