import { createContext, useContext } from "react"
import { FormattersDebugHighlights } from "@WebUtils/react/formatters/options/debugOptions/FormattersHighlighter"
import { DEFAULT_QUANTITIES_OPTIONS, QuantitiesOptions } from "@WebUtils/react/formatters/options/NumericValuesOptions/quantitiesOptions"
import { DEFAULT_PERCENT_OPTIONS, PercentOptions } from "@WebUtils/react/formatters/options/NumericValuesOptions/percentOptions"
import { DEFAULT_PRICE_OPTIONS, PriceOptions } from "@WebUtils/react/formatters/options/NumericValuesOptions/priceOptions"

type FormattersOptions = {
	percent: PercentOptions
	quantities: QuantitiesOptions
	price: PriceOptions
	debugHighlights: FormattersDebugHighlights
}

const DEFAULT_OPTIONS: FormattersOptions = {
	percent: DEFAULT_PERCENT_OPTIONS,
	quantities: DEFAULT_QUANTITIES_OPTIONS,
	price: DEFAULT_PRICE_OPTIONS,
	debugHighlights: "notAllowed",
}

const FormattersOptionsContext = createContext<FormattersOptions>( DEFAULT_OPTIONS )

export default function FormattersOptionsProvider( { options, children }: { options: FormattersOptions, children: React.ReactNode } ) {
	return <FormattersOptionsContext.Provider value={ options }>
		{ children }
	</FormattersOptionsContext.Provider>
}
export const useFormattersOptions = () => useContext( FormattersOptionsContext )
