import * as React from "react"
import { useState } from "react"
import { IFilter, QuickFilter } from "./Filter"
import { FormattedMessage } from "react-intl"
import QuickSearchInput from "./QuickSearchInput"
import Button from "@mui/material/Button"

export type QuickFilterFormProps = { handleFilterChange: ( filter: IFilter ) => void, table: string, handleSearch: ( searchString: string ) => void };

export const DefaultQuickFilterForm = ( props: QuickFilterFormProps ) => {
	const qFilter = new QuickFilter( props.table )
	const [ permFilter, setPermFilter ] = useState<string | undefined>( qFilter.filter.permFilter )

	const onPermissionClick = () => {
		const newPermission = permFilter === "my" ? "" : "my"
		setPermFilter( newPermission )
		const quickFilter: QuickFilter = new QuickFilter( props.table )
		if( newPermission ) {
			quickFilter.filter.permFilter = newPermission
		} else {
			delete quickFilter.filter.permFilter
		}
		quickFilter.saveToStorage()
		props.handleFilterChange( quickFilter.filter )
	}

	const onSearchSubmit = ( searchString: string ) => {
		props.handleSearch( searchString )
	}

	return (
		<>
			<QuickSearchInput onSearchSubmit={ onSearchSubmit } />
			<div style={ {
				flex: 1, flexGrow: 0, paddingTop: "0.2rem", marginLeft: "1rem",
			} }>
				<Button onClick={ onPermissionClick } variant={ permFilter === "my" ? "contained" : "outlined" } color={ "primary" } size={ "small" }
						sx={ { height: "1.5rem" } }>
					<FormattedMessage defaultMessage={ "Moje" } id={ "filterMyItems" } />
				</Button>
			</div>
		</>
	)
}
