import { AxiosError, AxiosResponse } from "axios"
import { MenuData } from "./MainMenu/MainMenu"
import { crmApi, getCrmBaseUrlWithLang } from "./api/CrmApi"
import { MobileLinkFactory } from "./MobileLinkFactory"
import { CountryOption } from "./form/QuickFilterForm"

type DataResponse = { users: [], currentUserId: number, serverUrl: string, baseUrl: string, countryOptionData: CountryOption[] }

class MobileCrmData {
	private static currentUserId: number

	private static users: Record<number, string>

	public static INIT_ACTION = "initData"

	public static FORM_ACTION = "getForm"

	public static FILTER_FORM_ACTION = "getFilterForm"

	public static LIST_ACTION = "getListData"

	public static FIRM_LIST_ACTION = "getFirmListData"

	public static RELATION_ACTION = "getEntityRelationData"

	public static RELATION_LIST_ACTION = "getEntityRelationListData"

	public static DELETE_ENTITY_ACTION = "deleteEntity"

	public static PERSON_LIST_ACTION = "getPersonList"

	public static COUNTRY_OPTION_DATA_ACTION = "getCountryOptionData"

	public static CONTACT_LIST_ACTION = "getContactList"

	public static ORDER_COLUMNS_ACTION = "getOrderColumns"

	public static DETAIL_VIEW_ACTION = "getDetailViewData"

	public static serverUrl: string

	private static menuDataProp: MenuData[]

	private static countryData: CountryOption[]

	static async loadData() {
		await crmApi.get( MobileLinkFactory.createActionLink( this.INIT_ACTION ) )
			.then( ( data: AxiosResponse<DataResponse> ) => {
				MobileCrmData.users = data.data.users
				MobileCrmData.currentUserId = data.data.currentUserId
				MobileCrmData.serverUrl = data.data.serverUrl
				MobileCrmData.countryData = data.data.countryOptionData
			} ).catch( ( error: AxiosError ) => {
				console.log( error.message )
				MobileCrmData.users = []
				MobileCrmData.currentUserId = 0
				MobileCrmData.serverUrl = ""
			} )
	}

	public static getCurrentUserId(): number {
		return this.currentUserId
	}

	static set menuData( value: MenuData[] ) {
		this.menuDataProp = value
	}

	static get menuData(): MenuData[] {
		return this.menuDataProp
	}

	static async loadMenuData() {
		await crmApi.get( MobileLinkFactory.createActionLink( "getMenuData" ) )
			.then( ( data: AxiosResponse<Array<MenuData>> ) => {
				MobileCrmData.menuData = data.data
			} ).catch( ( error ) => {
				console.log( error )
			} )
	}

	public static getCountryData(): CountryOption[] {
		return this.countryData
	}

	public static getUserNameById( userId: number ): string {
		return this.users[ userId ]
	}

	public static getBaseUrl(): string {
		return getCrmBaseUrlWithLang()
	}

	public static getActionUrl( action: string ): string {
		return this.getBaseUrl() + action
	}

	public static getDeleteEntityUrl( id: number, table: string ): string {
		return this.DELETE_ENTITY_ACTION + "?id=" + id.toString() + "&table=" + table
	}

	public static lastFormId: number
}

export default MobileCrmData
