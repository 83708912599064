import { FormattedMessage, useIntl } from "react-intl"
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat"
import { HighlightFormatter, useHighlightFormatter } from "@WebUtils/react/formatters/options/debugOptions/FormattersHighlighter"

type TranslateTextFuncParameters = {
	description?: string,
	values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
}

type TranslateTextProps = {
	msg: string,
	description?: string,
	values?: Record<string, React.ReactNode>
}

export type translateTextFunc = ( msg: string, parameters?: TranslateTextFuncParameters ) => string

export function useTranslateText(): translateTextFunc {
	const intl = useIntl()
	const highlight = useHighlightFormatter()
	return ( msg, parameters ) => highlight(
		intl.formatMessage( {
			defaultMessage: msg, id: msg, description: parameters?.description,
		}, parameters?.values ),
		"translatedText",
	)
}

export function TranslateText( {
	                               msg, description, values,
}: TranslateTextProps ) {
	return <HighlightFormatter formatterType={ "translatedText" }>
		<FormattedMessage
			defaultMessage={ msg }
			id={ msg }
			description={ description }
			values={ values }
		/>
	</HighlightFormatter>
}
