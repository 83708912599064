import { Alert, Box, Button, Collapse, FormControl, InputLabel, OutlinedInput } from "@mui/material"
import React, { ChangeEvent, useState } from "react"

import { IAuthTokens } from "../../auth/IAuthTokens"
import { useApi } from "../../api/ApiProvider"
import ResponseLoginForm from "../../auth/ResponseLoginForm"
import { FormattedMessage } from "react-intl"
import { HTTP_STATUS } from "../../api/api"

export type LoginFormProps = {
	handleLoginSuccess: ( response: ResponseLoginForm ) => void
}

export default function LoginForm( { handleLoginSuccess }: LoginFormProps ) {
	const [ login, setLogin ] = useState( "" )
	const [ password, setPassword ] = useState( "" )
	const [ isSent, setIsSent ] = useState( false )

	const [ error, setError ] = useState( false )
	const [ errorMessageTag, setErrorMessageTag ] = useState( <FormattedMessage id={ "loginFailGeneralError" }
																				defaultMessage={ "Došlo k neočekávané chybě. Zkuste to  prosím později." } /> )

	const api = useApi()

	function handleChange( e: ChangeEvent<HTMLInputElement> ) {
		const { value, name } = e.target
		switch( name ) {
			case "login":
				setLogin( value )
				break
			case "password":
				setPassword( value )
				break
		}
	}

	function handleSubmit( e: React.SyntheticEvent ) {
		setIsSent( true )
		setError( false )
		e.preventDefault()
		api.post( "/login", {
			login: login,
			password: password,
		} )
			.then( ( { data } ) => {
				setIsSent( false )
				handleLoginSuccess( data as IAuthTokens )
			} )
			.catch( ( { response } ) => {
				setIsSent( false )
				setError( true )
				if( response.status === HTTP_STATUS.C401_UNAUTHORIZED ) {
					// todo_pb create component that will render error
					const { code } = response.data.errors
					switch( code ) {
						case "INVALID_LOGIN_OR_PASSWORD":
							setErrorMessageTag( <FormattedMessage id={ "loginFailWrong" } defaultMessage={ "Zadané přihlašovací údaje nejsou správné." } /> )
							break
						case "ACCOUNT_DISABLED":
							setErrorMessageTag( <FormattedMessage id={ "loginFailBlocked" }
																  defaultMessage={ "Účet byl zablokován, kontaktujte prosím svého správce." } /> )
							break
						case "BANNED_SECURITY":
							setErrorMessageTag( <FormattedMessage id={ "loginFailSecurity" }
																  defaultMessage={ "Účet byl dočasně zablokován z důvodu bezpečnosti." } /> )
							break
						case "BANNED_TOO_MUCH_BAD_LOGIN":
							setErrorMessageTag( <FormattedMessage id={ "loginFailTooMuchBadLogin" }
																  defaultMessage={ "Účet dočasně zablokován. Příliš mnoho pokusů o přihlášení s nesprávným heslem." } /> )
							break
						case "BANNED_DEBT":
							setErrorMessageTag( <FormattedMessage id={ "loginFailExpired" }
																  defaultMessage={ "Účet byl zablokován z důvodu vypršení licence." } /> )
							break
						default:
							setErrorMessageTag( <FormattedMessage id={ "loginFailGeneralError" }
																  defaultMessage={ "Došlo k neočekávané chybě. Zkuste to prosím později." } /> )
							break
					}
				}
			} )
	}

	return <form onSubmit={ handleSubmit }>
		<Box
			sx={ {
				display: "flex",
				flexFlow: "column wrap",
				alignItems: "flex-end",
			} }
		>
			<FormControl
				color={ "primary" }
				fullWidth={ true }
				required={ true }
				sx={ { marginBottom: "0.8125rem" } }
			>
				<InputLabel htmlFor="my-input">
					<FormattedMessage id={ "loginName" } defaultMessage={ "Přihlašovací jméno" } />
				</InputLabel>
				<OutlinedInput
					onChange={ handleChange }
					name={ "login" }
					type={ "text" }
					label={ <FormattedMessage id={ "loginName" } defaultMessage={ "Přihlašovací jméno" } /> }
				/>
			</FormControl>
			<FormControl
				color={ "primary" }
				fullWidth={ true }
				required={ true }
				sx={ { marginBottom: "0.8125rem" } }
			>
				<InputLabel htmlFor="my-input">
					<FormattedMessage id={ "loginPassword" } defaultMessage={ "Heslo" } />
				</InputLabel>
				<OutlinedInput
					onChange={ handleChange }
					name="password"
					type={ "password" }
					label={ <FormattedMessage id={ "loginPassword" } defaultMessage={ "Heslo" } /> }
				/>
			</FormControl>
			<Box sx={ { width: "100%" } }>
				<Collapse in={ error }>
					<Alert severity="error">
						{ errorMessageTag }
					</Alert>
				</Collapse>
			</Box>

			<FormControl
				color={ "primary" }
				fullWidth={ false }
				required={ true }

			>
				<Button
					name={ "submit" }
					type={ "submit" }
					color={ "primary" }
					disabled={ isSent }
					variant={ "contained" }
					sx={ {
						background: '#FF6600',
						marginTop: '1em',
						'&:hover': {
							background: '#CC5200',
						},
					} }
				>
					<FormattedMessage id={ "loginSubmit" } defaultMessage={ "Přihlásit" } />
				</Button>
			</FormControl>

		</Box>
	</form>
}
