import { createTheme, Theme, useTheme } from "@mui/material"
import createPalette from "@mui/material/styles/createPalette"
import { ThemeOptions } from "@mui/material/styles/createTheme"
import createTypography from "@mui/material/styles/createTypography"
import { lightPaletteOptions } from "./mui-options/muiLightPaletteOptions"
import { typographyOptions } from "./mui-options/muiTypographyOptions"

export default function useMuiTheme(): Theme {
	const palette = createPalette( lightPaletteOptions )
	const typography = createTypography( palette, typographyOptions )
	const { breakpoints } = useTheme()

	const themeOptions: ThemeOptions = {
		// shadows: shadows,
		palette: {
			...lightPaletteOptions,
		},
		typography: typography,
		breakpoints: breakpoints,
		components: {
			MuiButton: {
				styleOverrides: {
					containedPrimary: {
						fontWeight: "bolder",
					},
				},
			},
		},
	}

	return createTheme( themeOptions )
}
