import { ApiProvider, ApiTypes } from "../../api/ApiProvider"
import UnloggedApp from "./UnloggedApp"
import { getAuthTokens } from "../../auth/getAuthTokens"
import React from "react"
import { MobileView } from "../MobileView"
import PageSpinner from "../../helper/PageSpinner"

export function AppContent() {
	const { loginToken } = getAuthTokens()
	const isUserLogged = loginToken !== undefined

	return (
		<React.Suspense fallback={ <PageSpinner /> }>
			{ isUserLogged ?
				<MobileView />
				:
				<ApiProvider apiType={ ApiTypes.apiNoAuth }>
					<UnloggedApp />
				</ApiProvider> }
		</React.Suspense>
	)
}
