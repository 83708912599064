import axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios"
import { getAuthTokens } from "../auth/getAuthTokens"
import { ResponseTokenRenew } from "../auth/ResponseTokenRenew"
import { setAuthTokens } from "../auth/setAuthTokens"
import { BASE_API_URL } from "./BASE_URL"
import { DHO_REQUEST_TOKEN } from "./ApiProvider"

let actualRenewRequest: Promise<void> | undefined

export enum HTTP_STATUS {
	// C200_OK = 200,
	C401_UNAUTHORIZED = 401,
	// C422_UNPROCESSABLE_ENTITY = 422,
}

export const URL_RENEW = "token/renew"
export const URL_LOGIN = "/login"
export const authTokensIdInLocalStorage = "token"

export function refreshRequestToken() {
	const authTokens = getAuthTokens()

	if( !actualRenewRequest ) {
		actualRenewRequest = axios.post<ResponseTokenRenew>( BASE_API_URL + URL_RENEW, undefined, {
			headers: new AxiosHeaders( {
				"Content-Type": "application/json;charset=utf-8",
				"Accept": "application/json",
				"Dho-Login-Token": authTokens.loginToken?.token as string,
			} ),
		} ).then( ( { data } ) => {
			setAuthTokens( data )
			actualRenewRequest = undefined
		} ).catch( () => {
			localStorage.removeItem( authTokensIdInLocalStorage )
			actualRenewRequest = undefined
			// todo redirect to login
			location.reload()
		} )
	}
	return actualRenewRequest
}

export async function onRejectedDefault( error: AxiosError ): Promise<unknown> {
	// @ts-ignore
	const originalRequest: InternalAxiosRequestConfig & { shouldRetry: boolean } = error?.config
	// renew is not sent when there is a 401 error in the login
	if( error.response?.status === HTTP_STATUS.C401_UNAUTHORIZED && !originalRequest.shouldRetry && originalRequest.url !== URL_LOGIN ) {
		originalRequest.shouldRetry = true
		return refreshRequestToken()
			.then( () => api( originalRequest ) )
	}
	return Promise.reject( error )
}

export function setDefaultApiRequestConfig( { headers }: AxiosRequestConfig ): void {
	const { requestToken } = getAuthTokens()

	if( headers instanceof AxiosHeaders ) {
		headers[ "Content-Type" ] = "application/json;charset=utf-8"
		headers[ "Accept" ] = "application/json"
		if( requestToken ) {
			headers[ DHO_REQUEST_TOKEN ] = requestToken.token
		}
	}
}

const api: AxiosInstance = axios.create( {
	baseURL: BASE_API_URL,
} )

api.interceptors.request.use(
	config => {
		setDefaultApiRequestConfig( config )
		return config
	},
	error => {
		void Promise.reject( error )
	},
)

api.interceptors.response.use( ( response ) => {
	return response
}, onRejectedDefault )
export default api