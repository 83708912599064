/* eslint-disable no-underscore-dangle */

export type IFilter = Record<string, string | object>

export type IQuickFilter = IFilter & { searchString?: string, permFilter?: string, countryCode?: string }

export type ISortFilter = IFilter & {
	orderColumn: string,
	orderType: string
}

abstract class AFilter<T extends IFilter> {
	public filter: T

	private readonly _storageKey: string

	protected constructor( table: string, key: string ) {
		this._storageKey = table + key
		this.filter = localStorage.getItem( this.storageKey ) === null ? {} as T : JSON.parse( localStorage.getItem( this.storageKey ) || "" ) as T
	}

	get storageKey(): string {
		return this._storageKey
	}

	saveToStorage() {
		localStorage.setItem( this.storageKey, JSON.stringify( this.filter ) )
	}

	removeFromStorage() {
		localStorage.removeItem( this.storageKey )
	}
}

export class QuickFilter extends AFilter<IQuickFilter> {
	constructor( table: string ) {
		super( table, "QuickFilter" )
	}
}

export class Filter extends AFilter<IFilter> {
	constructor( table: string ) {
		super( table, "Filter" )
	}
}

export class SortFilter extends AFilter<ISortFilter> {
	constructor( table: string ) {
		super( table, "SortFilter" )
	}
}