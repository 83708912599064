import React from "react"
import { Route, Routes } from "react-router-dom"
import MessageWrapper from '../MessageWrapper/MessageWrapper'
import { useMessageContext } from "../MessageWrapper/MessageWrapperProvider"
import LoginView from "../LoginForm/LoginView"

export default function UnloggedApp() {
	const {
		message,
	} = useMessageContext()
	return (
		<>
			{ message ? <MessageWrapper messageType="info" message={ message } /> : '' }
			<Routes>
				{/*<Route*/}
				{/*	path={ `/lostPassword` }*/}
				{/*	element={ <ForgottenPasswordView /> }*/}
				{/*/>*/}
				<Route path={ '*' } element={ <LoginView /> } />
			</Routes>
		</>
	)
}
