import { IAuthTokens } from "./IAuthTokens"

export const getAuthTokens = (): IAuthTokens => {
	const savedToken: string | null = localStorage.getItem( "token" )
	if( savedToken === null ) {
		return {
			requestToken: undefined,
			loginToken: undefined,
		}
	}
	const [ requestToken,
		requestExpiration,
		loginToken,
		loginExpiration ] = savedToken.split( "|" )
	// const currentTimestamp = Math.floor( Date.now() / 1000 )
	// let apiRequest
	// let apiLogin
	// if( Number( requestExpiration ) > currentTimestamp ) {
	const apiRequest = {
			token: requestToken,
			expiration: Number( requestExpiration ),
		}
	// }
	// if( Number( loginExpiration ) > currentTimestamp ) {
	const apiLogin = {
			token: loginToken,
			expiration: Number( loginExpiration ),
		}
	// }

	return {
		requestToken: apiRequest,
		loginToken: apiLogin,
	}
}