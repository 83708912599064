/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react"
import { ReactNode } from "react"
import "../css/mobile/bootstrap.scss"
import "../css/select2/select2.min.css"
// import "../submodule/crm/css/select2.less"
// import "../submodule/crm/css/externalCss.css"
import "../css/icon.less"
import { useParams } from "react-router-dom"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import FormPanels from "./FormPanels"
import FormTopBar from "./FormTopBar"

export type TabDefinition = { key: number, title: ReactNode }

export const EntityTabs = ( props: { value: number, setValue: React.Dispatch<React.SetStateAction<number>>, tabs: TabDefinition[] } ) => {
	const handleChange = ( _event: React.SyntheticEvent, newValue: number ) => {
		props.setValue( newValue )
	}

	return <Tabs
		value={ props.value }
		onChange={ handleChange }
		indicatorColor="secondary"
		textColor="inherit"
		aria-label="Form tabs"
		variant="scrollable"
		scrollButtons="auto"
	>
		{ props.tabs.map( ( tab ) => <Tab value={ tab.key } label={ tab.title } key={ tab.key } wrapped sx={ {
			textTransform: "initial", fontSize: "0.9rem", padding: "0.4rem 0.8rem",
		} } /> ) }
	</Tabs>
}

const Form = () => {
	const {
		table, id, firmDbId,
	} = useParams() as { table: string, id: string, firmDbId?: string }
	const idNumeric: number = parseInt( "" + id )

	console.assert( table !== undefined, "table is undefined" )

	return (
		<>
			<FormTopBar table={ table } id={ idNumeric } />
			<FormPanels table={ table } id={ idNumeric } firmDbId={ firmDbId } />
		</>
	)
}

export default Form