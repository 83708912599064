import axios, { AxiosInstance } from "axios"
import { BASE_API_URL, BASE_URL, MOBILE_API_CLIENT_TOKEN } from "./BASE_URL"
import { DHO_CLIENT_TOKEN, DHO_REQUEST_TOKEN } from "./ApiProvider"
import { getAuthTokens } from "../auth/getAuthTokens"
import { onRejectedDefault } from "./api"

// lang from browser
let lang = navigator.language.split( '-' )[ 0 ]
lang != 'cs' && lang != 'sk' && ( lang = 'en' )
export const CURRENT_LANG = lang

export function getCrmBaseUrlWithLang(): string {
	return BASE_URL + CURRENT_LANG + '/'
}

export const crmApi: AxiosInstance = axios.create( {
	baseURL: BASE_API_URL,
} )
crmApi.interceptors.request.use(
	( request ) => {
		request.params = { params: JSON.stringify( request.params ) }
		request.baseURL = getCrmBaseUrlWithLang()

		// noinspection TypeScriptValidateTypes
		request.headers[ "Content-Type" ] = `application/json;charset=utf-8`
		request.headers[ "Accept" ] = `application/json`

		const authTokens = getAuthTokens()
		request.headers[ DHO_CLIENT_TOKEN ] = MOBILE_API_CLIENT_TOKEN
		if( authTokens.requestToken ) {
			request.headers[ DHO_REQUEST_TOKEN ] = authTokens.requestToken.token
		}

		return request
	},
	( error ) => {
		// eslint-disable-next-line no-void
		void Promise.reject( error )
	},
)

crmApi.interceptors.response.use( ( response ) => response, onRejectedDefault )
