import * as React from "react"

export interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
	table: string
}

export function CachedTabPanel( props: TabPanelProps ) {
	const {
		children, value, index, table, ...other
	} = props

	return ( // using CSS trick to hide tabpanels instead of recreating them, with value === index on index change the content is removed
		<div
			role="tabpanel"
			hidden={ value !== index }
			id={ `main-tab-panel-${ index }` }
			key={ `main-tab-panel-${ table }-${ index }` }
			style={ { overflowY: "scroll", height: "85vh" } }
			className={ value === index ? "tab-active" : "tab-inactive" }
			{ ...other }
		>
			{ children }
		</div>
	)
}