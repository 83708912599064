import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt'
import { faCubes } from '@fortawesome/pro-solid-svg-icons/faCubes'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook'
import { faDollarSign } from "@fortawesome/pro-solid-svg-icons/faDollarSign"
import { faCalendarCheck } from "@fortawesome/pro-solid-svg-icons/faCalendarCheck"
import { faDraftingCompass } from "@fortawesome/pro-solid-svg-icons/faDraftingCompass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { faHandshake, IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

export default function MenuIcon( props: { iconString: IconProp } ) {
	let icon: IconDefinition

	switch( props.iconString ) {
		case 'home':
			icon = faHome
			break
		case 'cubes':
			icon = faCubes
			break
		case 'building':
		case 'far,fa-drafting-compass':
			icon = faDraftingCompass
			break
		case 'dollar-sign':
			icon = faDollarSign
			break
		case 'check-circle':
			icon = faCheckCircle
			break
		case 'far,sign-out-alt':
			icon = faSignOutAlt
			break
		case 'far,address-book':
			icon = faAddressBook
			break
		case 'far,handshake':
			icon = faHandshake
			break
		case 'far,calendar-check':
			icon = faCalendarCheck
			break

		default:
			console.error( "Unknown icon: ", props.iconString )
			icon = faCubes
	}

	return <FontAwesomeIcon icon={ icon } size="lg" className="icon" />
}