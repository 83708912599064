import { getCrmBaseUrlWithLang } from "./api/CrmApi"

export class MobileLinkFactory {
	static FORM = "form/"

	static FILTER = "filter/"

	static BASE = "/"

	static DETAIL = "detail/"

	public static createFormLink( table: string, id?: number ): string {
		return `/${ table }/${ this.FORM }${ id ? id.toString() : "0" }`
	}

	public static createListLink( table: string ): string {
		return this.BASE + table + '/'
	}

	public static createFilterLink( table: string ): string {
		return this.createListLink( table ) + this.FILTER
	}

	public static createDetailLink( table: string, id: number ): string {
		return this.BASE + table + '/' + this.DETAIL + id.toString()
	}

	public static createActionLink( action: string, params?: object ): string {
		let url = getCrmBaseUrlWithLang() + action
		if( params ) {
			url += "?" + this.encodeQueryData( params )
		}
		return url
	}

	private static encodeQueryData( data: object ): string {
		const returnValue: string[] = []
		data && Object.keys( data ).forEach( d => {
			returnValue.push( encodeURIComponent( d ) + "=" + encodeURIComponent( d ) )
		} )
		return returnValue.join( "&" )
	}
}