import * as React from "react"
import { FirmCustomerRelationData, FirmDbCountryData } from "../EntityType"
import ReactPlaceholder from "react-placeholder"
import FirmAddRelationButton from "../view/FirmAddRelationButton"
import { FormattedMessage } from "react-intl"

type Props = {
	detailData?: FirmDbCountryData;
};
export const FirmBasicInfoDetail = ( props: Props ) => {
	const detailData = props.detailData

	return (
		<ReactPlaceholder ready={ detailData !== null }
						  type={ "text" }
						  rows={ 15 }
						  showLoadingAnimation={ true }>
			{ detailData && <>
				<div className={ "firmBasicBox" }>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Název firmy:" } id={ "firmName" } />
						</div>
						<div className={ "boxRowData" }>
							{ detailData.name }
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Stav spolupráce:" } id={ "firmCooperation" } />
						</div>
						<div className={ "boxRowData" }>
									<span className={ "cooperation_status_" + detailData.cooperationStateCode }>
										{ detailData.cooperationState }
									</span>
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Vztah:" } id={ "firmRelationship" } />
						</div>
						<div className={ "boxRowData" }>
							<div style={ { display: "grid" } }>
								{ detailData.relation ? detailData.relation.map( ( relationData: FirmCustomerRelationData, index: number ) => {
									return <span key={ index }>
										{ relationData.relationType }
										-
										{ relationData.productCategory }
									</span>
								} ) : <span> - </span> }

							</div>
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "IČ:" } id={ "firmIdNumber" } />
						</div>
						<div className={ "boxRowData" }>
							{ detailData.idNumber }
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Sídlo:" } id={ "firmHeadquarters" } />
						</div>
						<div className={ "boxRowData" }>
							{ detailData.mainAddress }
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Provozovna:" } id={ "firmSubsidiary" } />
						</div>
						<div className={ "boxRowData" }>
							{ detailData.facilityAddress }
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Počet zaměstnanců:" } id={ "firmWorkerCount" } />
						</div>
						<div className={ "boxRowData" }>
							{ detailData.workerCount }
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Obrat:" } id={ "firmRevenue" } />
						</div>
						<div className={ "boxRowData" }>
							{ detailData.revenue }
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Datum založení:" } id={ "firmFounded" } />
						</div>
						<div className={ "boxRowData" }>
							{ detailData.dateCreated }
						</div>
					</div>
					<div className={ "firmBoxRow" }>
						<div className={ "boxRowTitle" }>
							<FormattedMessage defaultMessage={ "Právní forma:" } id={ "firmLegalStatus" } />
						</div>
						<div className={ "boxRowData" }>
							{ detailData.legalForm }
						</div>
					</div>
				</div>
				<div style={ { marginTop: "1rem", textAlign: "center" } }>
					<FirmAddRelationButton firmDbId={ detailData.firmDbId.toString() } />
				</div>
			</>
			}
		</ReactPlaceholder>
	)
}