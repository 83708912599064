import * as React from "react"
import { useEffect, useState } from "react"
import { ContactData, PersonData } from "../EntityType"
import { ContactItemView } from "../contact/ContactItemView"
import { NoDataWrapper } from "../NoDataWrapper"
import { PersonEditButton } from "./PersonEditButton"
import { FormattedMessage } from "react-intl"
import { fetchOptions, fetchRawContentData } from "../form/FormDataHelper"
import MobileCrmData from "../MobileCrmData"
import { FormDataType } from "../form/FormPanels"

export const PersonViewWrapper = ( { firmDbId, id, table }: FormDataType ) => {
	const [ personData, setPersonData ] = useState<PersonData[] | null>( null )
	const [ loading, setLoading ] = useState<boolean>( true )

	useEffect( () => {
		fetchRawContentData( MobileCrmData.PERSON_LIST_ACTION, fetchOptions( table, id, firmDbId ), setPersonData )
		setLoading( false )
	}, [ table, id, firmDbId ] )

	const items: React.ReactNode[] = []
	let contacts: React.ReactNode[] = []

	personData && personData.forEach( ( person: PersonData, index: number ) => {
		const personContact: ContactData[] = person.personContactEntries
		contacts = []
		items.push(
			// @ts-ignore
			<div className={ "personBox" } key={ index.toString() }>
				<div className={ "personName" }>
					<div className={ "title" }>
						<div>{ person.name }</div>
						<div>{ person.departmentName }</div>
					</div>
					<div className={ "menuItem" }><PersonEditButton personData={ person } /></div>
				</div>
				{
					personContact.length > 0 ? personContact.forEach( ( contact: ContactData, index: number ) => {
						contacts.push( <ContactItemView showEdit={ false } key={ index } contactData={ contact } /> )
					} ) : <div className={ "contactItem" }>
						<div><FormattedMessage defaultMessage={ "Žádná data k zobrazení" } id={ "noData" } /></div>
					</div>
				}
				{ contacts }
			</div>,
		)
	} )

	return (
		<div>
			{ loading === false && personData !== null && !personData.length && <NoDataWrapper /> }
			{ items }
		</div>
	)
}
