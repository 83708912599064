/* eslint-disable */
import { PaletteOptions } from "@mui/material"

export interface PaletteOptionsExtended extends PaletteOptions {
	// corporate: PaletteColorOptions,
}

export const lightPaletteOptions: PaletteOptionsExtended = {
	mode: "light",

	/// text
	// color=textPrimary,
	// color=textSecondary
	text: {
		primary: "#282B34",
		secondary: "#8E93A0",
		disabled: "#565C6B",
		// hint: "#565C6B",
	},
	/// background
	background: {
		default: "#EFF2F4",
		paper: "#FFFFFF",
	},
	///
	/// divider
	divider: "#E3E7EB",
	///
	primary: {
		main: "#e38911",
		dark: "#9b5c07",
		contrastText: "#FFFFFF",
	},

	secondary: {
		main: "#3B4549",
		dark: "#293032",
		contrastText: "#FFFFFF",
	},
}
