import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { MobileLinkFactory } from "../MobileLinkFactory"
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus"
import { canAddItem } from "../helper/ViewHelper"

export type ListTopBarMenuData = { table: string };

const ListTopBarMenu = ( props: ListTopBarMenuData ) => {
	if( canAddItem( props.table ) === false ) {
		return null
	}
	return (
		<span className={ `mobileListFilter` }>
			<Link to={ MobileLinkFactory.createFormLink( props.table, 0 ) }><FontAwesomeIcon icon={ faPlus } /></Link>
		</span>
	)
}

export default ListTopBarMenu