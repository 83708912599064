import * as React from "react"
import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import MobileCrmData from "../MobileCrmData"
import ReactPlaceholder from "react-placeholder"
import { crmApi } from "../api/CrmApi"
import { MobileLinkFactory } from "../MobileLinkFactory"
import $ from 'jquery'
import app from "../old/application"
import { FormExecutor } from "./FormExecutor"
import { useNavigate } from "react-router-dom"
import { Alert, Snackbar } from "@mui/material"
import { FormattedMessage } from "react-intl"

export type FormDataResponse = {
	formId: number,
	extHtml: string,
	javaScript: string,
	instanceToken: string
}

const FormDataLoader = ( { table, id, firmDbId }: {
	table?: string,
	id?: number,
	firmDbId?: number
} ) => {
	const [ formId, setFormId ] = useState<number | null>( null )
	const [ open, setOpen ] = React.useState( false )

	const navigate = useNavigate()

	if( MobileCrmData.lastFormId ) {
		console.log( "deleting formId", MobileCrmData.lastFormId )
		delete window[ MobileCrmData.lastFormId ]
		// @ts-ignore
		delete window[ MobileCrmData.lastFormId + "_relationFormModule" ]
	}

	useEffect( () => {
		const config = {
			"params": {
				"table": table,
				"id": id,
				"firmDbId": firmDbId,
			},
		}
		crmApi.get( MobileLinkFactory.createActionLink( MobileCrmData.FORM_ACTION ), config ).then( ( data: AxiosResponse<FormDataResponse> ) => {
			setFormId( data.data.formId )
			app.data.onload = []
			setTimeout( () => {
				FormExecutor( data.data, "mobileForm" )

				if( table ) {
					// @ts-ignore
					window[ data.data.formId ].mobileSaveCallback = () => {
						setOpen( true )
						setTimeout( () => {
							navigate( MobileLinkFactory.createListLink( table ) )
						}, 1000 )
					}
				}

				const $formTopButtons = $( "#formTopButtons" )
				$formTopButtons.html( "" )
				const submitClone = $( "div.fSubmit" ).clone()
				// noinspection JSUnresolvedReference
				submitClone.appendTo( $formTopButtons )
			}, 100 )
		} ).catch( ( error ) => {
			console.log( error.message )
		} )
	}, [ id, table, firmDbId, navigate ] )

	const handleClose = ( _event?: React.SyntheticEvent | Event, reason?: string ) => {
		if( reason === "clickaway" ) {
			return
		}

		setOpen( false )
	}

	return ( <ReactPlaceholder ready={ formId !== null } rows={ 15 } type={ "text" } showLoadingAnimation>
		<div id={ "mobileForm" } />
		<Snackbar open={ open } autoHideDuration={ 3000 } onClose={ handleClose }
				  anchorOrigin={ { horizontal: "center", vertical: "bottom" } }>
			<Alert severity="success" sx={ { width: "100%" } } onClose={ handleClose }>
				<FormattedMessage defaultMessage={ "Formulář byl uložen" } id={ "formSaved" } />
			</Alert>
		</Snackbar>
	</ReactPlaceholder> )
}

export default FormDataLoader