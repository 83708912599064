import * as React from "react"
import { MainMenuItem } from "./MainMenuItem"
import { MenuData } from "../MainMenu"
import { Link } from "react-router-dom"
import MenuIcon from "./MenuIcon"

interface MainMenuSectionProps {
	item: MenuData,
	allItems: MenuData[],
	isCompact: boolean,
	autoHideOnClickHandler: () => void
}

export class MainMenuSection extends React.PureComponent<MainMenuSectionProps, { isOpenedByClick: boolean, isOpenedByHover: boolean }> {
	constructor( props: MainMenuSectionProps ) {
		super( props )
		this.state = {
			isOpenedByClick: false,
			isOpenedByHover: false,
		}
	}

	render(): React.ReactNode {
		return (
			<ul
				className={ this.getClassName() }
				onMouseEnter={ this.handleOpenOnMouseEnter.bind( this ) }
				onMouseLeave={ this.handleCloseOnMouseLeave.bind( this ) }
			>
				<li>
					{ this.getItem() }
				</li>
				{ this.isOpened() && <ul>
					{ this.props.allItems.map( ( item ) => {
						if( this.props.item.id === item.parentId ) {
							return <MainMenuItem key={ item.id } item={ item } autoHideOnClickHandler={ this.props.autoHideOnClickHandler } />
						}
						return undefined
					} ) }
				</ul> }
			</ul>
		)
	}

	private handleClick() {
		this.setState( { isOpenedByClick: !this.state.isOpenedByClick } )
	}

	private handleOpenOnMouseEnter() {
		if( this.props.isCompact ) {
			this.setState( { isOpenedByHover: true } )
		}
	}

	private handleCloseOnMouseLeave() {
		if( this.props.isCompact ) {
			this.setState( { isOpenedByHover: false } )
		}
	}

	getItem(): React.ReactNode {
		if( this.props.item.shortUrl == null ) {
			console.error( "Missing shortUrl", this.props.item )
			return null
		}

		const childrenCount: number = this.props.allItems.filter( ( item ) => item.parentId === this.props.item.id ).length

		if( childrenCount === 0 ) {
			return (
				<Link
					onClick={ () => {
						this.props.autoHideOnClickHandler && this.props.autoHideOnClickHandler()
						document.title = this.props.item.name
					} }
					to={ this.props.item.shortUrl }
				>
					<MenuIcon iconString={ this.props.item.icon } />
					<span>{ this.props.item.name }</span>
				</Link>
			)
		} else {
			return (
				<span onClick={ this.handleClick.bind( this ) }>
					<MenuIcon iconString={ this.props.item.icon } />
					<span>{ this.props.item.name }</span>
				</span>
			)
		}
	}

	private isOpened(): boolean {
		// noinspection OverlyComplexBooleanExpressionJS
		return ( !this.props.isCompact && this.state.isOpenedByClick ) || ( this.props.isCompact && this.state.isOpenedByHover )
	}

	getClassName(): string {
		if( this.isOpened() ) {
			return "opened"
		}
		return ""
	}
}