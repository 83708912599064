import FormControl from "@mui/material/FormControl"
import { IconButton, Input, InputAdornment } from "@mui/material"
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch"
import { faDeleteLeft } from "@fortawesome/pro-solid-svg-icons/faDeleteLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { useIntl } from "react-intl"

export default function QuickSearchInput( { onSearchSubmit }: { onSearchSubmit: ( searchString: string ) => void } ) {
	const intl = useIntl()
	const [ searchString, setSearchString ] = React.useState( '' ) // Add this line

	const handleClick = () => {
		onSearchSubmit( searchString )
	}
	const handleClear = () => {
		onSearchSubmit( '' )
		setSearchString( '' )
	}
	return (
		<FormControl sx={ { m: 1, flex: 1 } } variant="filled">
			<Input
				type={ 'text' }
				onChange={ ( event ) => {
					setSearchString( event.target.value )
				} }
				onKeyUp={ ( event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
					if( event.key === 'Enter' ) {
						onSearchSubmit( searchString )
					}
				} }
				value={ searchString }
				placeholder={ intl.formatMessage( { defaultMessage: "Zadejte hledaný text", id: "searchInputText" } ) }
				endAdornment={
					<InputAdornment position="end">
						{ searchString &&
							<IconButton
								onClick={ handleClear }
							>
								<FontAwesomeIcon icon={ faDeleteLeft } size={ "sm" } />
							</IconButton>
						}
						<IconButton
							onClick={ handleClick }
						>
							<FontAwesomeIcon icon={ faSearch } size={ "sm" } />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	)
}