import { PickedDistanceUnits } from "@WebUtils/utility/baseConverter/baseConvertDistance"
import { PickedVolumeUnits } from "@WebUtils/utility/baseConverter/baseConvertVolume"
import { PickedSpeedUnits } from "@WebUtils/utility/baseConverter/baseConvertSpeed"
import { ConsumptionUnits } from "@WebUtils/utility/baseConverter/baseConvertConsumption"
import { FormatNumberOptions } from "react-intl"

export type QuantityUnits = {
	distance: PickedDistanceUnits
	volume: PickedVolumeUnits
	speed: PickedSpeedUnits
	consumption: ConsumptionUnits
}

export type QuantityName = keyof QuantityUnits

export type QuantityOptions<NAME extends QuantityName> = {
	apiUnit: QuantityUnits[NAME],
	unit: QuantityUnits[NAME]
	opts: Omit<FormatNumberOptions, 'unit' | 'style'>
}

export type QuantitiesOptions = {
	distance: QuantityOptions<'distance'>
	speed: QuantityOptions<'speed'>
	volume: QuantityOptions<'volume'>
	consumption: QuantityOptions<'consumption'>
}

export const DEFAULT_QUANTITIES_OPTIONS: QuantitiesOptions = {
	distance: {
		apiUnit: 'm',
		unit: 'km',
		opts: {
			unitDisplay: "narrow",
			maximumFractionDigits: 2,
		},
	},
	speed: {
		apiUnit: 'm/s',
		unit: 'km/h',
		opts: {
			unitDisplay: "narrow",
			maximumFractionDigits: 1,
		},
	},
	volume: {
		apiUnit: 'm3',
		unit: 'l',
		opts: {
			maximumFractionDigits: 2,
			unitDisplay: "narrow",
		},
	},
	consumption: {
		apiUnit: 'l/100km',
		unit: 'l/100km',
		opts: {
			maximumFractionDigits: 2,
			unitDisplay: "narrow",
		},
	},
}
