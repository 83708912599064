import { crmApi } from "../../../api/CrmApi"
import { MobileLinkFactory } from "../../../MobileLinkFactory"
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"

export type Param = string | number | Record<string, string | number>

export type FormParams = {
	"table": string,
	"formData": Record<string, Param>,
	"instanceToken"?: string
}

export class DataLoader {
	public async fetchData( action: string, params?: FormParams ) {
		const config = {
			"params": params,
		} as AxiosRequestConfig

		return crmApi.get( MobileLinkFactory.createActionLink( action ), config ).then( ( response: AxiosResponse ) => {
			const data = response.data

			data && this.outputMessages( data )

			return data
		} ).catch( ( error: AxiosError ) => {
			console.log( error.message )
		} )
	}

	public outputMessages( data: object ) {
		// @ts-ignore
		window.app.outputMessages( data )
	}
}