import * as React from "react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { IFilter } from "../form/Filter"
import { MobileListDataWrapper } from "../list/MobileListDataWrapper"
import FilterFormDataLoader from "../form/FilterFormDataLoader"
import { FilterFormNavBar } from "../form/FilterFormNavBar"
import { ListOrderFilter } from "../form/ListOrderFilter"
import { QuickFilterForm } from "../form/QuickFilterForm"
import Box from '@mui/material/Box'
import { TabPanel } from "../helper/TabPanel"
import { EntityTabs, TabDefinition } from "../form/Form"
import MobilePageViewTop from "./MobilePageViewTop"
import { MainMenu } from "../MainMenu/MainMenu"
import { FIRM_GLOBAL_VIEW } from "../helper/ViewHelper"
import { FormattedMessage } from "react-intl"

export const MobilePageView = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [ filter, setFilter ] = useState<IFilter>()
	const [ tabFilter, setTabFilter ] = useState<boolean>( false )
	const [ filterChange, setFilterChange ] = useState<boolean>( false )
	const [ searchString, setSearchString ] = useState<string | undefined>()

	let { table } = useParams<{ table: string }>()
	if( !table || table === "home" || table === "activity" ) { // default route/table is home
		table = "calendar"
	}
	const [ value, setValue ] = React.useState( 0 )

	useEffect( () => {
		setTabFilter( false )
		setSearchString( undefined )
		setFilterChange( true )
	}, [ table ] )

	let tabClassName = localStorage.getItem( table + "Filter" ) ? "activeFilter" : ""	// advanced filter tab is active

	const handleFilterChange = ( filter: IFilter ): void => {
		console.log( "handleFilterChange", filter )
		setFilter( filter )
		setFilterChange( !filterChange )
		tabClassName = localStorage.getItem( table + "Filter" ) ? "activeFilter" : ""
	}
	const tabs: TabDefinition[] = [ { key: 0, title: "Seznam" } ]

	if( table != FIRM_GLOBAL_VIEW ) {
		tabs.push(
			{ key: 1, title: <span className={ tabClassName }><FormattedMessage defaultMessage="Pokročilý filtr" id={ "advancedFilter" } /></span> },
			{ key: 2, title: "Řazení" },
		)
	}
	return (
		<>
			<MobilePageViewTop table={ table }>
				<MainMenu autoHideOnLinkClick={ true } />
			</MobilePageViewTop>
			{ tabFilter ? <span className={ "mobileListFilter" }>
				<FilterFormNavBar
					handleFilterChange={ handleFilterChange }
					setFilterReload={ setFilterChange }
					isReload={ filterChange }
					table={ table }
				/>
			</span> : '' }

			<Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
				<EntityTabs value={ value } tabs={ tabs } setValue={ setValue } />
			</Box>

			<TabPanel index={ 0 } value={ value } table={ table }>
				<QuickFilterForm handleFilterChange={ handleFilterChange } table={ table } handleSearch={ value => setSearchString( value ) } />
				{ table == FIRM_GLOBAL_VIEW && !searchString ?
					<div className={ "noDataToShow" }><FormattedMessage defaultMessage={ "Zadejte název firmy" } id={ "firmSearchHint" } /></div>
					: <MobileListDataWrapper table={ table } searchString={ searchString } filter={ filter } />
				}
			</TabPanel>
			<TabPanel index={ 1 } value={ value } table={ table }>
				<FilterFormDataLoader handleFilterChange={ handleFilterChange } table={ table } />
			</TabPanel>
			<TabPanel index={ 2 } value={ value } table={ table }>
				<ListOrderFilter handleFilterChange={ handleFilterChange } table={ table } />
			</TabPanel>
		</>
	)
}
