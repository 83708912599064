import { MessageWrapperProvider } from "../MessageWrapper/MessageWrapperProvider"
import { ThemeProvider } from "@mui/material"
import { DHOIntlProvider } from "@CrmCommon/intl/IntlProvider"
import { AppContent } from "./AppContent"
import React from "react"
import useMuiTheme from "../../styles/muiTheme"
import { BrowserRouter } from "react-router-dom"

export function App() {
	const createdMuiTheme = useMuiTheme()
	return (
		// <React.StrictMode>
		<MessageWrapperProvider>
			<ThemeProvider theme={ createdMuiTheme }>
				<DHOIntlProvider>
					<BrowserRouter>
						<AppContent />
					</BrowserRouter>
				</DHOIntlProvider>
			</ThemeProvider>
		</MessageWrapperProvider>
		// </React.StrictMode>
	)
}
