/* eslint-disable camelcase */
// noinspection SpellCheckingInspection

import * as React from "react"
import { PersonData } from "../EntityType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil"
import MobileCrmData from "../MobileCrmData"

type EditUrlParams = {
	id: number,
	firmDbId?: number,
	firm_db_id_cust?: number,
	is_in_dialog: number
}

type PersonEditButtonProps = {
	personData: PersonData
}

function escapeRegExp( string: string ) {
	return string.replace( /([!$()*+./:=?[\\\]^{|}~])/g, "\\$1" )
}

export function PersonEditButton( props: PersonEditButtonProps ) {
	if( props.personData.permission.canEdit === false ) {
		return null
	}

	const urlParams: EditUrlParams = {
		id: props.personData.id,
		is_in_dialog: 1,
	}

	let urlAction = "firmUserPersonEdit"

	if( props.personData.firmCustomerId ) {
		urlParams.firm_db_id_cust = props.personData.firmCustomerId
	} else {
		urlParams.firmDbId = props.personData.firmDbId
		urlAction = "firmPersonEdit"
	}
	return ( <a onClick={ () => {
			const rand = Math.floor( ( Math.random() * 10000 ) + 1 )
			const newDialogId = "mobile_dialog" + rand.toString()
			const escapedId = escapeRegExp( newDialogId )
			$( "body" ).append( $( "<div id=\"" + newDialogId + "\" class=\"newEntityDialog\" style=\"display:none;\"><div class=\"contents\"></div></div>" ) )
			app.dialog( MobileCrmData.serverUrl + "/firm/" + urlAction + "?mobile=1", urlParams, {
				title: "Upravit osobu",
				width: 950,
				height: 650,
				// eslint-disable-next-line func-names
				close: function() {
					app.destroyDialog( "#" + escapedId )
					$( "#" + escapedId ).remove()
				},
			}, escapedId )
		} }>
			<FontAwesomeIcon icon={ faPencil } />
		</a>
	)
}