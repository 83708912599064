import { FormatNumberOptions } from "react-intl"
import { PercentType } from "@WebUtils/utility/baseConverter/baseConvertPercent"

export type PercentOptions = {
	apiType: PercentType
	type: PercentType
	opts: Omit<FormatNumberOptions, 'style'>
}

export const DEFAULT_PERCENT_OPTIONS: PercentOptions = {
	apiType: 'coefficient',
	type: 'percent',
	opts: {
		maximumFractionDigits: 4,
	},
}
