import * as React from "react"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import MobileCrmData from "./MobileCrmData"
import { Typography } from "@mui/material"

export type Route = { shortUrl: string, name: string }

export const ListNavBarTitle = () => {
	const [ menuPath, setMenuPath ] = useState<Array<Route>>( [] )
	const location = useLocation()

	useEffect( () => {
		if( MobileCrmData ) {
			setMenuPath( MobileCrmData.menuData )
		}
	}, [] )

	const path = location.pathname === "/" ? "/home" : location.pathname

	return (
		<Typography
			variant="h2"
			noWrap
			component="div"
		>
			{ menuPath?.map( ( route: Route ) => {
				if( path.includes( route.shortUrl ) ) {
					return route.name
				}
				return null
			} )
			}
		</Typography>
	)
}
