import { darken, styled } from "@mui/material"
import { useFormattersOptions } from "@WebUtils/react/formatters/options/FormattersOptionsProvider"

export type FormatterName =
	"price" |
	"date" |
	"dateRange" |
	"duration" |
	"distance" |
	"number" |
	"speed" |
	"volume" |
	"consumption" |
	"translatedText" |
	"percent"

export type FormattersDebugHighlights = Record<FormatterName | "enabled", boolean> | "notAllowed"

export const DEFAULT_FORMATTERS_HIGHLIGHTS: FormattersDebugHighlights = {
	enabled: false,
	translatedText: true,
	price: false,
	date: false,
	dateRange: false,
	duration: false,
	distance: false,
	number: false,
	speed: false,
	volume: false,
	consumption: false,
	percent: false,
}

export const getFormatterHighlightAbbr = ( type: FormatterName ) => {
	switch( type ) {
		case "translatedText":
			return "TT"
		case "price":
			return "PRI"
		case "date":
			return "DAT"
		case "dateRange":
			return "DRA"
		case "duration":
			return "DUR"
		case "distance":
			return "DIS"
		case "volume":
			return "VOL"
		case "consumption":
			return "CON"
		case "speed":
			return "SPE"
		case "number":
			return "NUM"
		case "percent":
			return "PER"
		default:
			return "DEPRECATED"
	}
}

function getHighlightSymbols( type: FormatterName, isHook: boolean ) {
	const highlightAppend = isHook ? "\u2017" : "_"
	return [
		`{${ getFormatterHighlightAbbr( type ) }${ highlightAppend }`, `${ highlightAppend }${ getFormatterHighlightAbbr( type ) }}`,
	]
}

const StyledSpan = styled( "span" )( {
	// color: "purple",
	// color: "#E75480",
	// color: 'seagreen'
	color: darken( "#FF6347", 0.1 ),
	// textShadow: '0 1px 0 rgba(0, 0, 0, 0.4)'
} )

export type HighlightFunc = ( value: string, formatterType: FormatterName ) => string

export function useHighlightFormatter(): HighlightFunc {
	const formattersHighlight = useFormattersOptions().debugHighlights

	if( formattersHighlight === "notAllowed" || !formattersHighlight.enabled ) {
		return ( value: string ) => value
	}

	return ( value: string, formatterType: FormatterName ) => {
		const highlights = getHighlightSymbols( formatterType, true )
		if( formattersHighlight[ formatterType ] ) {
			return `${ highlights[ 0 ] }${ value }${ highlights[ 1 ] }`
		} else {
			return value
		}
	}
}

export function HighlightFormatter( { children, formatterType }: { children: JSX.Element | null, formatterType: FormatterName } ) {
	const formattersHighlight = useFormattersOptions().debugHighlights
	if( formattersHighlight === "notAllowed" || !formattersHighlight.enabled ) {
		return children
	}
	const highlights = getHighlightSymbols( formatterType, false )
	if( formattersHighlight[ formatterType ] ) {
		return <span>
			<StyledSpan>
				{ highlights[ 0 ] }
			</StyledSpan>
			<span>
				{ children }
			</span>
			<StyledSpan>
				{ highlights[ 1 ] }
			</StyledSpan>
		</span>
	} else {
		return children
	}
}
