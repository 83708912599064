import Box from "@mui/material/Box"
import FormDataLoader from "./FormDataLoader"
import RelationSummary from "../summary/RelationSummary"
import { NoDataWrapper } from "../NoDataWrapper"
import { PersonViewWrapper } from "../person/PersonViewWrapper"
import { ContactViewWrapper } from "../contact/ContactViewWrapper"
import * as React from "react"
import { EntityTabs, TabDefinition } from "./Form"
import { CachedTabPanel } from "../helper/CachedTabPanel"
import { TabPanel } from "../helper/TabPanel"

export function makeTabDefinition( table: string, id: number ) {
	const tabs: TabDefinition[] = [ { key: 0, title: "Základní" } ]

	if( table != "product" && table != "project" && id ) {
		tabs.push( { key: 1, title: "Související" } )
		tabs.push( { key: 2, title: "Kontaktní osoby" } )
		tabs.push( { key: 3, title: "Kontakty" } )
	}
	return tabs
}

export type FormDataType = {
	table: string
	id: number
	firmDbId?: string
}

const FormPanels = ( { firmDbId, id, table }: FormDataType ) => {
	const [ value, setValue ] = React.useState( 0 )
	const [ lastId, setLastId ] = React.useState( 0 )

	const tabs = makeTabDefinition( table, id )

	if( lastId != id ) {
		setValue( 0 )
		setLastId( id )
	}

	return (
		<>
			<Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
				<EntityTabs value={ value } tabs={ tabs } setValue={ setValue } />
			</Box>
			<CachedTabPanel index={ 0 } value={ value } table={ table }>
				<FormDataLoader table={ table } id={ id } firmDbId={ firmDbId ? Number.parseInt( firmDbId ) : undefined } />
			</CachedTabPanel>
			<CachedTabPanel index={ 1 } value={ value } table={ table }>
				{ id ? <RelationSummary table={ table } id={ id } /> : <NoDataWrapper /> }
			</CachedTabPanel>
			<TabPanel index={ 2 } value={ value } table={ table }>
				<PersonViewWrapper table={ table } id={ id } firmDbId={ firmDbId } />
			</TabPanel>
			<TabPanel index={ 3 } value={ value } table={ table }>
				<ContactViewWrapper table={ table } id={ id } firmDbId={ firmDbId } />
			</TabPanel>
		</>
	)
}
export default FormPanels