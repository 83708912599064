import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { crmApi } from "../api/CrmApi"
import { MobileLinkFactory } from "../MobileLinkFactory"
import { ResponseData } from "../DataLoader"

// @ts-ignore
const fetchContentData = ( action: string, fetchOptions: AxiosRequestConfig, contentCallback: ( content ) => void ) => {
	crmApi.get( MobileLinkFactory.createActionLink( action ), fetchOptions )
		.then( ( axiosResponse: AxiosResponse<ResponseData> ): void => {
			const data = axiosResponse.data
			data && window.app.outputMessages( data )
			contentCallback( data.content )
		} )
		.catch( ( error: AxiosError ) => console.log( error.message ) )
}

export const fetchRawContentData = ( action: string, fetchOptions: AxiosRequestConfig, contentCallback: ( content: never ) => void ) => {
	crmApi.get( MobileLinkFactory.createActionLink( action ), fetchOptions )
		.then( ( axiosResponse: AxiosResponse<ResponseData> ): void => {
			const data = axiosResponse.data
			data && window.app.outputMessages( data )
			contentCallback( data as never )
		} )
		.catch( ( error: AxiosError ) => console.log( error.message ) )
}

export function fetchOptions( table: string, idNumeric: number, firmDbId?: string  ): AxiosRequestConfig<unknown> {
	return {
		"params": {
			"id": idNumeric,
			"table": table,
			"firmDbId": firmDbId,	// from firm db detail
		},
	} as AxiosRequestConfig
}