/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MainMenuSection } from "./Component/MainMenuSection"
import "./MainMenu.less"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import throttle from "lodash-es/throttle"
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes"
import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars"
import { IconButton } from "@mui/material"
import MobileCrmData from "../MobileCrmData"

export interface MainMenuProps {
	autoHideOnLinkClick: boolean,
}

export interface MenuData {
	id: number,
	icon: IconProp,
	parentId: number,
	name: string,
	shortUrl: string,
}

export type MainMenuState = { dataLoaded: boolean, isShown: boolean, isSticky: boolean, lastScrollTop: number }

export class MainMenu extends React.PureComponent<MainMenuProps, MainMenuState> {
	constructor( props: MainMenuProps ) {
		super( props )
		this.state = {
			dataLoaded: MobileCrmData.menuData.length > 0,
			isShown: false,
			isSticky: false,
			lastScrollTop: 0,
		}
		window.addEventListener( "resize", throttle( this.handleWindowResize.bind( this ), 200 ) )
		window.addEventListener( "scroll", throttle( this.handleOnScroll.bind( this ), 200 ) )
	}

	componentDidUpdate(): void {
		this.handleWindowResize()
	}

	render(): React.ReactNode {
		return (
			<>
				<IconButton
					size="large"
					color="inherit"
					edge="start"
					aria-label="menu"
					sx={ { mr: 2 } }
					disableTouchRipple
					onClick={ this.handleChangeMenuShow.bind( this ) }
				>
					<FontAwesomeIcon icon={ this.state.isShown ? faTimes : faBars } />
				</IconButton>
				<div className={ `mainMenu printHidden ${ this.isSticky() ? "sticky" : "" }` }>
					{ this.state.dataLoaded
						? this.renderMenu()
						: '' }
				</div>
			</>
		)
	}

	renderMenu(): React.ReactNode {
		return (
			<div className={ `menu ${ this.state.isShown ? "shown" : "" }` }>
				{ MobileCrmData.menuData.map( ( item ) => {
					if( item.parentId === null ) {
						return <MainMenuSection
							key={ item.id }
							item={ item }
							allItems={ MobileCrmData.menuData }
							isCompact={ false }
							autoHideOnClickHandler={ this.props.autoHideOnLinkClick ? this.handleChangeMenuShow.bind( this ) : () => {
							} }
						/>
					}
					return undefined
				} ) }
			</div>
		)
	}

	async componentDidMount() {
		if( this.state.dataLoaded ) {
			return
		}

		// try {
		// 	crmApi.post( MobileLinkFactory.createActionLink( "getMenuData" ), {} )
		// 		.then( ( data: AxiosResponse<unknown> ) => {
		// 			this.setState( { dataLoaded: true, data: data.data as MenuData[] } )
		// 		} ).catch( error => {
		// 		console.log( error )
		// 	} )
		// } catch( e ) {
		// 	console.log( e )
		// }
	}

	private handleWindowResize() {
		// Bootstrap breakpoints
		MainMenu.makePageContentScrollable( !this.state.isShown )
	}

	private handleChangeMenuShow(): void {
		const isShown = !this.state.isShown
		MainMenu.makePageContentScrollable( !isShown )
		this.setState( { isShown: !this.state.isShown } )
	}

	private handleOnScroll(): void {
		if( this.state.isShown ) {
			return
		}
		const st = window.scrollY || document.documentElement.scrollTop
		this.setState( { isSticky: st < this.state.lastScrollTop } )
		this.setState( { lastScrollTop: st <= 0 ? 0 : st } )
	}

	private isSticky(): boolean {
		return this.state.isSticky
	}

	private static makePageContentScrollable( isScrollable: boolean ): void {
		if( isScrollable ) {
			document.body.classList.remove( "menuOpened" )
		} else {
			document.body.classList.add( "menuOpened" )
		}
	}
}
