import * as React from 'react'
import { ReactNode } from 'react'
import OpportunityListItem, { OpportunityDataProps } from "../opportunity/OpportunityListItem"
import ListItem, { ListItemDataProps } from "./ListItem"
import { TaskDataProps, TaskListItem } from "../task/TaskListItem"
import { FirmCustomerDataProps, FirmCustomerListItem } from "../firm/FirmCustomerListItem"
import { ProjectDataProps, ProjectListItem } from "../project/ProjectListItem"
import { MeetingDataProps, MeetingListItem } from "../meeting/MeetingListItem"
import { CalendarDataProps, CalendarListItem } from "../calendar/CalendarListItem"
import { FirmDbCountryListItem, FirmListDataProps } from "../firm/FirmDbCountryListItem"
import { FIRM_GLOBAL_VIEW } from "../helper/ViewHelper"

export const ListItemFactory = ( props: ListItemDataProps ): ReactNode => {
	switch( props.table ) {
		case "opportunity":
			return ( <OpportunityListItem { ...props as OpportunityDataProps } /> )
		case "task":
			return ( <TaskListItem { ...props as TaskDataProps } /> )
		case "meeting":
			return ( <MeetingListItem { ...props as MeetingDataProps } /> )
		case "order":
			return ( <ListItem { ...props } /> )
		case "project":
			return ( <ProjectListItem { ...props as ProjectDataProps } /> )
		case "product":
			return ( <ListItem { ...props } /> )
		case FIRM_GLOBAL_VIEW:
			return ( <FirmDbCountryListItem { ...props as FirmListDataProps } /> )
		case "firmCustomer":
			return ( <FirmCustomerListItem { ...props as FirmCustomerDataProps } /> )
		case "calendar":
			return ( <CalendarListItem { ...props as CalendarDataProps } /> )
		default:
			return ( <ListItem { ...props } /> )
	}
}
