/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { Route, Routes } from "react-router-dom"
import { MobileTemplate } from "../MobileTemplate"
import "../css/mobile/menu.less"
import "../css/mobile/mobile.less"
import FilterForm from "../form/FilterForm"
import LogoutPage from "../LogoutPage"
import { MobilePageView } from "./MobilePageView"
import { MobileDetailView } from "./MobileDetailView"
import { ApiProvider, ApiTypes } from "../api/ApiProvider"
import UnknownPage from "../UnknownPage"
import * as Sentry from "@sentry/react"
import Form from "../form/Form"

export * from "../MobileTemplate"

const SentryRoutes = Sentry.withSentryReactRouterV6Routing( Routes )

export const MobileView = () => {
	return (
		<ApiProvider apiType={ ApiTypes.crmApi }>
			<React.Suspense fallback={ "loading" }>
				<MobileTemplate>
					<div
						style={ {
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						} }
					>
						<div
							id={ "page-wrap" }
							style={ {
								flex: 1,
								maxWidth: "100%",
							} }
						>
							<SentryRoutes>
								<Route
									path={ "/:table/form/:id/firmDbId/:firmDbId" }
									element={ <Form /> }
								/>
								<Route
									path={ `/:table/form/:id/firmCustomerId/:firmCustomerId` }
									element={ <Form /> }
								/>
								<Route
									path={ `/:table/form/:id` }
									element={ <Form /> }
								/>
								<Route
									path={ `/:table/filter` }
									element={ <FilterForm /> }
								/>
								<Route
									path={ `/logout` }
									element={ <LogoutPage /> }
								/>
								<Route
									path={ `/` }
									element={ <MobilePageView /> }
								/>
								<Route
									path={ `/home` }
									element={ <MobilePageView /> }
								/>
								<Route
									path={ `/:table/detail/:id` }
									element={ <MobileDetailView /> }
								/>
								<Route
									path={ `/:table` }
									element={ <MobilePageView /> }
								/>
								{/* unknown route */ }
								<Route path={ '*' } element={ <UnknownPage /> } />
							</SentryRoutes>
						</div>
					</div>
				</MobileTemplate>
			</React.Suspense>
		</ApiProvider>
	)
}
