import { Alert, Box, Card, CardContent, Grid, styled, Typography } from "@mui/material"
import LoginForm from "./LoginForm"
import ResponseLoginForm from "../../auth/ResponseLoginForm"
import { setAuthTokens } from "../../auth/setAuthTokens"
import React from "react"
import { useIntl } from "react-intl"

const LoginWrapper = styled( Grid )( {
	height: "100vh",
} )

export default function LoginView() {
	const [ showLoginTimeoutError, setShowLoginTimeoutError ] = React.useState( false )

	const { formatMessage } = useIntl()

	function handleLoginSuccess( response: ResponseLoginForm ) {
		setAuthTokens( response )
		setShowLoginTimeoutError( false )
		location.reload()	// asi kvuli token local storage, asi ma byt i v js
	}

	return (
		<LoginWrapper container justifyContent={ 'center' } alignItems={ "center" }>
			<Grid sx={ { m: [ '2.2rem', 0, 0 ] } }>
				<Box key={ 1 } style={ { textAlign: 'center' } }>
					<img src={ "/expanzo-logo.jpg" } />
				</Box>
				<Typography variant="h2" sx={ {
					margin: '2em auto .3em',
					fontSize: '1.5rem',
					fontWeight: 700,
					textAlign: "center",
				} }
				>
					{ formatMessage( { defaultMessage: "Přihlášení do aplikace", id: "loginFormTitle" } ) }
				</Typography>
				<Card>
					<CardContent sx={ { p: [ '1rem 1rem', '1.5rem 2rem', '1rem 1rem' ] } }>
						{
							showLoginTimeoutError
							&& <Alert severity={ "error" }>
								Vypršela platnost vašeho přihlášení.
								<br />
								Přihlaste se, prosím, znovu
							</Alert>
						}
						<LoginForm handleLoginSuccess={ handleLoginSuccess } />
						<Grid container marginTop="1em">
							<Grid item xs={ 6 }>
								<a href={ "https://crm.expanzo.com/account/lostPassword" } className={ "body1" }
								>
									{ formatMessage( { defaultMessage: "Zapomenuté heslo", id: "loginForgottenPassword" } ) }
								</a>
							</Grid>
							<Grid item xs={ 6 } />
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</LoginWrapper>
	)
}
