// zurek local
// export const BASE_API_URL = `${ process.env.REACT_APP_API_BASE_URL || "http://127.0.0.1:8000/api/v1/" }`

// export const TEST_ACCESS_TOKEN = `${ process.env.REACT_APP_API_CLIENT_TOKEN || "639b1fc8e17e01_64629069" }`

//  server crmmobile@logbookie.eu
export const BASE_API_URL = `${ process.env.REACT_APP_API_BASE_URL || "https://testapi.expanzo.com/api/v1/" }`
// export const TEST_ACCESS_TOKEN = `${ process.env.REACT_APP_API_CLIENT_TOKEN }`

export const BASE_URL = BASE_API_URL + "crmMobile/"
export const MOBILE_API_CLIENT_TOKEN = "nXCufl5wZfqHzhX2xZAuhZdbuFtG7jw3hKGvtJV5porjeeQRz62zGYKATKr89IYzOI3hs6xHHhrhR9M6RoZ0qHSTsjBFWGCj7rZMdUF072K9OoSkDtfUH6ldpxzqTHsa"
