import * as React from "react"
import { useEffect, useState } from "react"
import MobileCrmData from "../MobileCrmData"
import { useParams } from "react-router-dom"
import { DataLoader, FormParams } from "@CrmCommon/loader/DataLoader"
import ReactPlaceholder from "react-placeholder"
import "react-placeholder/lib/reactPlaceholder.css"
import { Filter, IFilter } from "./Filter"
import { FilterFormNavBar } from "./FilterFormNavBar"
import { Box } from "@material-ui/core"
import { FormExecutor } from "./FormExecutor"
import { FormDataResponse } from "./FormDataLoader"
import { Alert, Snackbar } from "@mui/material"
import { FormattedMessage } from "react-intl"

export function filterDataToMap( filterData: IFilter ): Map<string, string> {
	const resultMap = new Map<string, string>()
	const processMap = new Map( Object.entries( filterData ) )

	// @ts-ignore
	processMap.forEach( ( fieldValue: string | Array | boolean, fieldName: string ): void => {
		if( Array.isArray( fieldValue ) ) {
			fieldValue.forEach( ( multiselectValue, multiselectItemIndex ): void => {
				resultMap.set( fieldName + "[" + ( multiselectItemIndex > 0 ? multiselectItemIndex : "" ) + "]", multiselectValue )
			} )
		} else if( typeof fieldValue === "boolean" ) {
			resultMap.set( fieldName, fieldValue ? "1" : "0" )
		} else {
			resultMap.set( fieldName, fieldValue )
		}
	} )

	return resultMap
}

function getStorageName( table: string ) {
	return table + "FilterFormToken"
}

export function getListFilterFormInstanceToken( table: string ) {
	return localStorage.getItem( getStorageName( table ) )
}

const FilterFormDataLoader = ( props: {
	table: string,
	handleFilterChange?: ( filter: IFilter ) => void
} ) => {
	let { table } = useParams() as {
		table: string
	}
	const [ filterFormId, setFilterFormId ] = useState<number | null>( null )
	const [ reload, setReload ] = useState<boolean>( false )
	table = props.table ? props.table : ""
	const [ open, setOpen ] = React.useState( false )

	useEffect( () => {
		const filter = new Filter( table )
		const loader = new DataLoader()
		let instanceToken: string | null = getListFilterFormInstanceToken( table ) || null

		const params: FormParams = {
			"table": table,
			"formData": {},
		}

		filterDataToMap( filter.filter ).forEach( ( value: string, key: string ) => {
			params[ "formData" ][ key ] = value
		} )

		if( instanceToken ) {
			params[ 'instanceToken' ] = instanceToken
		}

		loader.fetchData( MobileCrmData.FILTER_FORM_ACTION, params ).then( ( data: FormDataResponse ) => {
			setFilterFormId( data.formId )
			if( data.instanceToken ) {
				localStorage.setItem( getStorageName( table ), data.instanceToken )
				instanceToken = data.instanceToken
			}
			FormExecutor( data, "mobileFilterForm" )
		} ).catch( reason => {
			console.log( reason )
		} )
	}, [ reload, table ] )

	const handleClose = ( _event?: React.SyntheticEvent | Event, reason?: string ) => {
		if( reason === "clickaway" ) {
			return
		}

		setOpen( false )
	}

	const handleSave = ( filter: IFilter ) => {
		props.handleFilterChange && props.handleFilterChange( filter )
		setOpen( true )
	}

	return ( <>
			<Box id={ "mobileFilterForm" } style={ filterFormId ? { display: "block" } : { display: "none" } } />
			<ReactPlaceholder ready={ filterFormId !== null } rows={ 15 } type={ "text" } showLoadingAnimation>
				<Box
					style={ {
						margin: "0 auto", width: "5.5em", display: "flex", justifyContent: "space-between",
					} }
				>
					<FilterFormNavBar handleFilterChange={ handleSave } setFilterReload={ setReload } isReload={ reload } table={ table } />
				</Box>
			</ReactPlaceholder>
			<Snackbar open={ open } autoHideDuration={ 3000 } onClose={ handleClose } sx={ { top: "500px" } }
					  anchorOrigin={ { horizontal: "center", vertical: "bottom" } }>
				<Alert severity="success" sx={ { width: "100%" } } onClose={ handleClose }>
					<FormattedMessage defaultMessage={ "Filtr byl uložen" } id={ "filterSaved" } />
				</Alert>
			</Snackbar>
		</>

	)
}

export default FilterFormDataLoader