import * as React from "react"

type MobileDialogProps = { show: boolean, showCallback: ( setState: boolean ) => void, content?: JSX.Element, title?: string }

export const MobileDialog = ( props: MobileDialogProps ) => {
	const style = props.show ? "grid" : "none"
	return <div style={ { display: style } } className={ "mobileDialog" }>
		{ props.content }
		<button className={ "btn btn-primary" } onClick={ () => {
			props.showCallback( false )
		} }>Zavřít
		</button>
	</div>
}
