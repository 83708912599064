import React, { useEffect, useState } from 'react'
import { Alert, Box, Collapse, IconButton } from '@mui/material'
import { TranslateText } from "@WebUtils/react/formatters/useTranslateText"
import CloseIcon from '@mui/icons-material/Close'
import { AlertColor } from '@mui/material/Alert'

type Props = {
	messageType: AlertColor | undefined;
	message: string;
}

export default function MessageWrapper( props: Props ) {
	const { messageType, message } = props
	const [ open, setOpen ] = useState( true )

	const toggleOpenAndResetMessageValue = ()=>{
		setTimeout( () =>{
			setOpen( false )
		}, 3000 )
	}

	useEffect( () => {
		toggleOpenAndResetMessageValue()
	}, [] )
	return (
		<Box
			sx={{
				width: 'auto', position: 'fixed', bottom: 40, left: '50%', transform: 'translateX(-50%)', zIndex: 1000,
			}}
		>
			<Collapse in={open}>
				<Alert
					severity={messageType}
					variant='standard'
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setOpen( false )
							}}
						>
							<CloseIcon fontSize="inherit" />

						</IconButton>
					}
					sx={{
						mb: 2,
					}}
				>
					<TranslateText msg={message} />
				</Alert>
			</Collapse>
		</Box>
	)
}
