/// <reference path="../../submodule/core/js/application.js" />

import { IFormObject } from "./IFormObject"

type ajaxResponseData = {
	content: any,
	errors: string,
};

export class RelationFormModule {

	private readonly firmUserHandlerClass: string = "crm\\model\\firmDb\\FirmUserHandler"
	private readonly formId: string

	//form object
	private formObject: IFormObject

	//repeater properties
	private repeaterFieldName: string
	private primaryDateFieldName: string
	private primaryDateField = null

	//next actions properties
	private firmName: string
	private urlParams: {}

	//load firm note
	private firmId: number = null
	private firmHandler: string = null

	//relation summary
	private summaryContainerId: string = null
	private entityTableId: string = null
	private entityId: string = null
	private formToken: string = null

	constructor( formId: string ) {
		this.formId = formId
		this.initAddNote()
	}

	private initAddNote(): void {
		const descriptionLabel = $( "#" + this.formId + " .relationDescription" )
		if( descriptionLabel.length > 0 ) {
			this.updateFirmIdFromField()
			if( this.firmId === null ) {
				descriptionLabel.find( "i" ).hide()
			}
		}
	}

	private updateFirmIdFromField(): void {
		this.firmId = null
		this.firmHandler = null
		const fieldFirmId = $( this.createSelector( "firm_db_id[id]" ) )
		if( fieldFirmId.length > 0 ) {
			const fieldFirmHandler = $( this.createSelector( "firm_db_id[handler]" ) )
			const firmId = fieldFirmId.val()
			if( firmId ) {
				this.firmId = parseInt( firmId )
				this.firmHandler = fieldFirmHandler.val()
			}
		}
	}

	private createSelector( name: string ): string {
		const nameReg: string = app.escapeRegExp( name )
		return "#" + this.formId + " input[name='" + nameReg + "']"
	}

	private appendDialog(): string {
		const rand = Math.floor( ( Math.random() * 10000 ) + 1 )
		const newDialogId = this.formId + "_dialog" + rand
		$( "body" ).append( $( "<div id=\"" + newDialogId + "\" class=\"newEntityDialog\" style=\"display:none;\"><div class=\"contents\"></div></div>" ) )

		return app.escapeRegExp( newDialogId )
	}

	private formReload(): void {
		this.getForm().reload()
	}

	private getForm(): IFormObject {
		if( this.formObject === undefined ) {
			this.formObject = window[ this.formId ]
		}

		return this.formObject
	}

	public copyToClipboard( e: Event ): void {
		const elem = e.target
		e.preventDefault()
		let temp = $( "<textarea>" )
		$( "body" ).append( temp )
		temp.val( $( elem ).parents( "div.contactDiv" ).text() ).select()
		try {
			document.execCommand( "copy" )
		}
		catch( err ) {
			// @ts-ignore
			if( window.ffclipboard ) {	// https://github.com/myplaceonline/myplaceonline_ffclipboard
				// @ts-ignore
				window.ffclipboard.setText( temp.val() )
			} else {
				// @ts-ignore
				window.prompt( "Zkopírovat do schránky: Ctrl C, Enter", temp.val() )
			}
		}
		temp.remove()
		app.info_message( "Kontakt zkopírován do schránky" )
	}

	public updateContactOption( selectSelector: string, personOptionHtml: string, personId: string ) {
		let selectField = $( "#" + this.formId ).find( "#" + app.escapeRegExp( selectSelector ) )
		if( selectField.length ) {
			let option = selectField.find( "option[value='" + personId + "']" )
			if( option.length ) {
				option.text( personOptionHtml )
				selectField.trigger( "change" )
			} else {
				let newOption = new Option( personOptionHtml, personId, true, true )
				selectField.append( newOption ).trigger( "change" )
			}
		}

	}

	private showEditAfterImport( url: string, title: string, firmDbIdCust: number ): void {
		const newDialogId = this.appendDialog()
		const _this = this
		app.dialog( url,
			{
				is_in_dialog: 1,
				dialog_parent_form_id: this.formId,
			},
			{
				title: title,
				width: 950,
				height: 650,
				close: function () {
					app.destroyDialog( "#" + newDialogId )
					if( firmDbIdCust ) {
						$( _this.createSelector( "firm_db_id[id]" ) ).val( firmDbIdCust )
						$( _this.createSelector( "firm_db_id[handler]" ) ).val( _this.firmUserHandlerClass )
					}
					_this.formReload()
				},
			}, newDialogId )
	}

	public firmImport( firmUid: string, editUrl: string, title: string ) {
		const _this = this
		const options = {
			data: {
				uid: firmUid,
			},
			url: app.getUrl( "freeFirmGlobalImport", { noView: 1 }, "firm" ),
			global: true,
			dataType: "json",
			success: function ( data: ajaxResponseData ) {
				if( data.content ) {
					app.info_message( app.get_lang( "firm.import.success" ) )
					editUrl += data.content
					_this.showEditAfterImport( editUrl, title, data.content )
				} else {
					app.info_message( data.errors )
				}

			},
		}
		app.ajax_request( options )
	}

	public firmAutoImport( firmDbId: number ) {
		const _this = this
		const options = {
			data: {
				firmDbId: firmDbId,
			},
			url: app.getUrl( "freeFirmGlobalImport", { noView: 1 }, "firm" ),
			global: true,
			dataType: "json",
			success: function ( data: ajaxResponseData ) {
				if( data.content ) {
					app.info_message( app.get_lang( "firm.import.success" ) )
					$( _this.createSelector( "firm_db_id[id]" ) ).val( data.content )
					$( _this.createSelector( "firm_db_id[handler]" ) ).val( _this.firmUserHandlerClass )
				} else {
					app.info_message( data.errors )
				}

			},
		}
		app.ajax_request( options )
	}

	public editPerson( url: string, title: string, elem: HTMLElement ) {
		const field = $( elem )
		let dropdown = field.parents( ".select2-dropdown" )
		let selectField
		let selector
		if( dropdown.length ) {
			let dropdownId = dropdown.attr( "id" )
			selector = app.escapeRegExp( dropdownId.replace( /_dropdown$/g, "" ) )
		} else {
			selectField = field.parents( ".field_contact_entry_id" ).find( "select" )
			selector = app.escapeRegExp( selectField.attr( "id" ) )
		}
		const newDialogId = this.appendDialog()
		app.dialog( url,
			{
				is_in_dialog: 1,
				dialog_parent_form_id: this.formId,
				field_selector: selector,
			},
			{
				title: title,
				width: 950,
				height: 650,
				close: function () {
					app.destroyDialog( "#" + newDialogId )
					$( "#" + newDialogId ).remove()
				},
			}, newDialogId )
	}

	public setNextActionProperties( firmName: string, column: string, entityId: number, firmColumn: string, firmId: number ) {
		this.firmName = firmName
		this.urlParams = {
			"formLayoutTypeCode": "action",
			"parentFormId": this.formId,
			"parentFieldId": "date_next_action",
		}
		this.urlParams[ column ] = entityId

		if( firmColumn && firmId ) {
			this.urlParams[ firmColumn ] = firmId
		}
	}

	public addNewFirmUser( formToken: string ) {
		const url = app.getUrl( "firmEdit", {}, "firm" )
		const title = app.get_lang( "firm.person.create.firm" )
		const newDialogId = this.appendDialog()
		let width = 0
		let height = 0

		const _this = this
		const options = {
			is_in_dialog: 1,
			dialog_parent_form_id: this.formId,
			dialog_parent_form_token: formToken,
		}
		app.dialog( url, options, {
			title: title,
			width: width,
			height: height,
			close: function () {
				app.destroyDialog( "#" + newDialogId )
				$( "#" + newDialogId ).remove()
				_this.formReload()
			},
		}, newDialogId )
	}

	public addNextAction( type: string, name: string ) {
		const nextDateAction = $( "#" + this.formId ).find( "#date_next_action" ).val()
		let action = "taskEdit"
		if( type === "meeting" ) {
			action = "meetingEdit"
		} else {
			this.urlParams[ "task_type_id" ] = type
		}
		this.urlParams[ "name" ] = name + " - " + this.firmName
		if( nextDateAction ) {
			this.urlParams[ "dateNextAction" ] = nextDateAction
		}

		// @ts-ignore
		if( app.isMobile() ) {
			this.urlParams[ "mobile" ] = 1
		}

		let url = app.getFullUrl( action, this.urlParams, "task" )

		const _this = this
		const newDialogId = this.appendDialog()
		app.dialog( url, { is_in_dialog: 1 }, {
			title: app.get_lang( "opportunity.add.action" ),
			width: 0,
			height: 0,
			close: function () {
				app.destroyDialog( "#" + newDialogId )
				_this.formReload()

			},
		}, newDialogId )
	}

	public setRepeater( repeaterFieldName: string, primaryDateFieldName: string ): void {
		this.repeaterFieldName = repeaterFieldName
		this.primaryDateFieldName = primaryDateFieldName
	}

	public initRepeaterField(): void {
		const form = $( "#" + this.getForm().formId )
		this.primaryDateField = form.find( "#" + this.primaryDateFieldName )
		let _this = this
		this.primaryDateField.on( "change", function () {
			_this.repeaterFieldSwitch()
		} )
		this.repeaterFieldSwitch()
	}

	public repeaterFieldSwitch(): void {
		const value: string = this.primaryDateField.val()
		//disable repeater field when date is empty
		if( !value ) {
			this.getForm().fieldFunctions[ this.repeaterFieldName ].disable()
		} else {
			this.getForm().fieldFunctions[ this.repeaterFieldName ].enable()
		}
	}

	public loadRelationSummary(): void {
		if( this.summaryContainerId && this.entityTableId && this.entityId && this.formToken ) {
			const options = {
				data: {
					tableId: this.entityTableId,
					entityId: this.entityId,
					formToken: this.formToken,
					formId: this.formId,
				},
				url: app.get_url( "loadEntityRelationData", {}, "entity" ),
				//dataType: "json",
				global: false,
			}
			app.ajax_request( options, "#" + this.summaryContainerId )
		} else {
			console.log( "Missing params for loadRelationSummary" )
		}
	}

	public initRelationSummary( containerId: string, tableId: string, entityId: string, formToken: string ): void {
		this.summaryContainerId = containerId
		this.entityTableId = tableId
		this.entityId = entityId
		this.formToken = formToken
	}

	public showEdit( url: string, title: string, plugin: string ) {
		const newDialogId = this.appendDialog()
		app.dialog( url,
			{
				is_in_dialog: 1,
			}, {
				title: title,
				width: 950,
				height: 650,
				close: function () {
					app.destroyDialog( "#" + newDialogId )
					$( "#" + newDialogId ).remove()
				},
			}, newDialogId )
	}

	public showAdd( url: string, title: string, width: number, height: number ) {
		if( typeof width === "undefined" ) {
			width = 0
		}
		if( typeof height === "undefined" ) {
			height = 0
		}
		const newDialogId = this.appendDialog()
		const _this = this
		app.dialog( url,
			{
				is_in_dialog: 1,
				dialog_parent_form_id: this.formId,
			},
			{
				title: title,
				width: width,
				height: height,
				close: function () {
					app.destroyDialog( "#" + newDialogId )
					$( "#" + newDialogId ).remove()
					_this.formReload()
				},
			}, newDialogId )
	}

	public loadFirmNote(): void {
		this.updateFirmIdFromField()
		if( this.firmId === null || this.firmHandler === null ) {
			return
		}
		const descriptionLabel = $( "#" + this.formId + " .relationDescription" )
		if( descriptionLabel.length > 0 ) {
			const firmColumn = this.firmHandler === this.firmUserHandlerClass ? "firm_db_id_cust" : "firm_db_id"
			const options = {
				data: {
					firmId: this.firmId,
					firmColumn: firmColumn,
				},
				url: app.get_url( "loadFirmNote", null, "firm" ),
				global: false,
				dataType: "json",
				success: function ( data: ajaxResponseData ) {
					if( !data.errors ) {
						descriptionLabel.parent().find( "textarea" ).val( data.content )
					}
				},
			}
			app.ajax_request( options )
		}
	}

}